import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { IFilter } from "@/core/entity/IFilter";
import NoteService from "@/core/services/NoteService";

@Module
export default class NoteModule extends VuexModule {
  notePage: ITableResponse<any> = {
    data: [],
    total: 0,
    pages: 0,
  }

  warningPage: any = {
    data: [],
    total: 0,
    pages: 0,
  }

  @Action
  [Actions.LOAD_NOTES](filter: IFilter) {
    NoteService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_NOTES, res)
    })
  }

  @Action
  [Actions.LOAD_WARNING_NOTES](filter: any) {
    NoteService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_WARNING_NOTES, res)
    })
  }

  @Mutation
  [Mutations.SET_NOTES](res) {
    this.notePage = res;
  }

  @Mutation
  [Mutations.SET_WARNING_NOTES](res) {
    this.warningPage = res;
  }
}