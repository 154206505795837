import { BaseService } from "@/core/services/BaseService";

class CurrencyService extends BaseService<any> {
  endpoint = "/currency";

  async list() {
    return await this.http().get(this.endpoint).then(res => {
      return res.data
    })
  }
}

export default new CurrencyService()