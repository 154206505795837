import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ProcessService from "@/core/services/ProcessService";

@Module
export default class ProcessModule extends VuexModule {
  allPage = <any>{
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  activePage = <any>{
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  completedPage = <any>{
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }
  entity = <any>{}


  @Action
  [Actions.LOAD_ALL_PROCESS](filter) {
    console.log("LOAD_ALL_PROCESS")
    ProcessService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ALL_PROCESS, {loading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_ACTIVE_PROCESS](filter) {
    ProcessService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVE_PROCESS, {loading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_COMPLETED_PROCESS](filter) {
    ProcessService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_COMPLETED_PROCESS, {loading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_PROCESS](id) {
    ProcessService.get(id).then(res => {
      this.context.commit(Mutations.SET_PROCESS, res)
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: true,
        viewPendingActivities: true
      })
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: true})
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false)
    })
  }

  @Mutation
  [Mutations.SET_ALL_PROCESS](data) {
    this.allPage = data;
  }

  @Mutation
  [Mutations.SET_ACTIVE_PROCESS](data) {
    this.activePage = data;
  }

  @Mutation
  [Mutations.SET_COMPLETED_PROCESS](data) {
    console.log(data)
    this.completedPage = data;
  }

  @Mutation
  [Mutations.SET_PROCESS](data) {
    this.entity = data
  }

  @Mutation
  [Mutations.ADDED_PROCESS]() {

  }

}