import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ActionViewService from "@/core/services/ActionViewService";


@Module
export default class ActionViewModule extends VuexModule {
  page: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_ACTION_VIEWS](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ActionViewService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTION_VIEWS, res)
    }).finally(()=>{
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  [Actions.LOAD_ACTION_VIEWS_PANEL](filter: any) {
    ActionViewService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTION_VIEWS, res)
    }).finally(()=>{
    })
  }

  @Mutation
  [Mutations.SET_ACTION_VIEWS](data) {
    this.page = data;
  }
}