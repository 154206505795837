import {BaseService} from "@/core/services/BaseService";

class LogReportService extends BaseService<any> {
  endpoint = "/log-report";

  async updateStatus(id, status) {
    return this.http().patch(this.endpoint + "/" + id + "/status/" + status).then(res => {
      return res.data;
    })
  }
  async run(id){
    return this.http().get(this.endpoint + "/" + id + "/run/").then(res => {
      return res.data;
    })
  }
}

export default new LogReportService();