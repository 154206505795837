import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ServiceService from "@/core/services/ServiceService";

@Module
export class ServiceModule extends VuexModule {
  page: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  @Action
  [Actions.LOAD_SERVICES](filter) {
    ServiceService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_SERVICES, {loading: false, data: res})
    })
  }

  @Mutation
  [Mutations.SET_SERVICES](data) {
    this.page = data;
  }
}