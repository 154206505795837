import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ITableResponse } from "@/core/entity/ITableResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { IFilter } from "@/core/entity/IFilter";
import TimelineService from "@/core/services/TimelineService";

@Module
export default class TimelineModule extends VuexModule {
  timelinePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_TIMELINES](filter: IFilter) {
    TimelineService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TIMELINES, res)
    })
  }
O
  @Mutation
  [Mutations.SET_TIMELINES](data) {
    this.timelinePage = data;
  }

}