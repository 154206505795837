import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { IFilter } from "@/core/entity/IFilter";
import LoginHistoryService from "@/core/services/LoginHistoryService";


@Module
export default class LoginModule extends VuexModule {
  loginPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0
  }

  @Action
  async [Actions.LOAD_LOGIN_AUDITS](filter: IFilter) {
    await LoginHistoryService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_LOGIN_AUDITS, res);
    })
  }

  @Mutation
  [Mutations.SET_LOGIN_AUDITS](data: ITableResponse<any>) {
    this.loginPage = data;
  }
}