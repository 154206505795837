import { BaseService } from "@/core/services/BaseService";

class ReserveService extends BaseService<any> {
  endpoint = "/reserve";

  async waive(id) {
    return this.http().delete(this.endpoint + "/" + id + "/waive").then(res => {
      return res.data;
    })
  }

}

export default new ReserveService();