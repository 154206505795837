import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class PanelModule extends VuexModule {
  //
  panelData = {
    isShow: false,
    type: 'CLAIM',
    objectId: '',
    isLoading: true,
  }

  baseModal = {
    isShow: false,
    type: 'CLAIM',
    objectId: '',
    isLoading: true,
  }

  genericTaskModal = {
    isShow: false,
    objectType: 'TASK'
  }

  tagModal = {
    show: false,
    objectType: '',
    objectId: '',
    processType : '',
    isLoading: true,
  }


  processModal = <any>{
    type: '',
    show: false,
  }


  @Action
  ['SHOW_TAG_MODAL'](data) {
    this.context.commit('SET_TAG_MODAL', {
      objectType: data.objectType,
      objectId: data.objectId,
      processType : data.processType,
      show: true,
      isLoading: false
    })
  }

  @Action
  ['SHOW_PROCESS_MODAL'](data) {
    this.context.commit('SET_PROCESS_MODAL', {type: data.type, show: true})
  }

  @Action
  ['SHOW_MODAL'](data) {
    this.context.commit('SET_BASE_MODAL', {type: data.type, objectId: data.id, isShow: true, isLoading: false})
  }

  @Action
  ['LOAD_PANEL_DATA'](data) {
    this.context.commit('SET_PANEL_DATA', {type: data.type, objectId: data.id, isShow: true, isLoading: true})
  }

  @Mutation
  ['SET_PANEL_DATA'](data) {
    this.panelData = data
  }

  @Mutation
  ['SET_PANEL_STATUS'](isShow) {
    this.panelData.isShow = isShow;
  }


  @Mutation
  ['SET_MODAL_STATUS'](isShow) {
    this.baseModal.isShow = isShow;
  }

  @Mutation
  ['SET_PANEL_LOADING'](isLoading) {
    this.panelData.isLoading = isLoading;
  }

  @Mutation
  ['SET_BASE_MODAL'](data) {
    this.baseModal.type = data.type;
    this.baseModal.objectId = data.objectId;
    this.baseModal.isShow = data.isShow;
    this.baseModal.isLoading = data.isLoading;
  }

  @Mutation
  ['SET_GENERIC_MODAL'](isShow) {
    this.genericTaskModal.isShow = isShow.isShow;
    this.genericTaskModal.objectType = isShow.objectType
  }

  @Mutation
  ['SET_TAG_MODAL'](data: any) {
    this.tagModal = data;
  }

  @Mutation
  ['SET_PROCESS_MODAL'](data: any) {
    this.processModal = data;
  }

  @Mutation
  ['SET_HIDE_TAG_MODAL']() {
    this.tagModal.show = false;
  }

  @Mutation
  ['SET_HIDE_PROCESS_MODAL']() {
    this.processModal.show = false;
  }
}