import {BaseService} from "@/core/services/BaseService";

class FileShareService extends BaseService<any> {
  endpoint = "/file-share"

  async accessFile(fileId, password) {
    return await this.http().get(this.endpoint + "/access/" + fileId + "/" + password).then(res => {
      return res.data;
    })
  }

  async download(fileId, password) {
    return await this.http().get(this.endpoint + "/access/download/" + fileId + "/" + password,
      {responseType: "blob"}).then(res => {
      return res;
    })
  }
}

export default new FileShareService();