import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ContactService from "@/core/services/ContactService";

@Module
export default class ContactModule extends VuexModule {
  contactPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_CONTACTS](filter) {
    ContactService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CONTACTS, res)
    })
  }

  @Mutation
  [Mutations.SET_CONTACTS](data: ITableResponse<any>) {
    this.contactPage = data;
  }
}