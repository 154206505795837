import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import { IUser } from "@/core/entity/IUser";
import UserService from "@/core/services/UserService";
import RoleService from "@/core/services/RoleService";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY } from "@/core/config/Constant";

@Module
export default class AuthModule extends VuexModule {
  userPage: ITableResponse<IUser> = {
    total: 0,
    pages: 0,
    data: []
  };
  user: any = {};

  isImpersonal: boolean = false;
  loginHistoryPage: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: []
  }

  theme: string = '#296472';

  background: string = '';
  userRoles = [];
  roles = [];

  get getUsers() {
    return this.userPage;
  }

  get getUser() {
    return this.user
  }

  @Mutation
  [Mutations.SET_USERS](data: any) {
    this.userPage = data;
  }

  @Mutation
  [Mutations.SET_USER](data: any) {
    this.user = data;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](data: any) {
    this.userRoles = data;
  }

  @Mutation
  [Mutations.SET_ROLES](data: any) {
    this.roles = data;
  }

  @Mutation
  [Mutations.SET_LOGIN_HISTORY](data: ITableResponse<any>) {
    this.loginHistoryPage = data
  }

  @Action
  [Actions.LOAD_USERS](filter: any) {
    UserService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_USERS, res);
    })
  }

  @Action
  [Actions.LOAD_LOGIN_HISTORY](loadFilter: any) {
    UserService.loginHistory(loadFilter.id, loadFilter.filter).then(res => {
      this.context.commit(Mutations.SET_LOGIN_HISTORY, res);
    })
  }

  @Action
  [Actions.LOAD_USER](id) {
    UserService.get(id).then(res => {
      this.context.commit(Mutations.SET_USER, res);
    })
  }

  @Action
  async [Actions.LOAD_USER_ROLES](loadFilter: any) {
    await UserService.roles(loadFilter.id).then(res => {
      this.context.commit(Mutations.SET_USER_ROLES, res)
    })
  }

  @Action
  async [Actions.LOAD_ROLES]() {
    await RoleService.list().then(res => {
      this.context.commit(Mutations.SET_ROLES, res)
    })
  }

  @Action
  ['LOAD_THEME']() {
    const theme = CookieService.get(COOKIE_KEY.THEME);
    if (theme) {
      this.context.commit('SET_THEME', theme);
    } else {
      this.context.commit('SET_THEME', '#296472')
    }
  }

  @Action
  ['PRESET_THEME'](payload) {
    CookieService.save(COOKIE_KEY.THEME, payload, "3Y");
    this.context.commit('SET_THEME', payload);
  }

  @Mutation
  ['SET_BACKGROUND'](background: string) {
    this.background = background;
  }

  @Mutation
  ['SET_THEME'](theme: string) {
    this.theme = theme;
  }

  @Action
  ['GET_IMPERSONAL']() {
    const im = CookieService.get(COOKIE_KEY.IMPERSONAL);
    if (im === 'true') {
      this.context.commit('SET_IMPERSONAL', true)
    } else {
      this.context.commit('SET_IMPERSONAL', false)
    }

  }

  @Mutation
  ['SET_IMPERSONAL'](value: boolean) {
    this.isImpersonal = value;
  }
}