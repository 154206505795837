import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import type {IFilter} from "@/core/entity/IFilter";
import InvoiceService from "@/core/services/InvoiceService";
import type {ISearchFilter} from "@/core/entity/ISearchFilter";
import CookieService from "@/core/services/CookieService";
import {COOKIE_KEY} from "@/core/config/Constant";

@Module
export default class InvoiceModule extends VuexModule {
  claimInvoicePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  claimInvoicePageFinalized: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  claimInvoiceAll: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  invoiceViewPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  listInvoicePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  invoice = {}

  @Action
  [Actions.LOAD_LIST_INVOICES](filter: IFilter) {
    InvoiceService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_LIST_INVOICES, res)
    })
  }

  @Action
  [Actions.LOAD_CLAIM_INVOICES](filter: IFilter) {
    InvoiceService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CLAIM_INVOICES, res)
    })
  }

  @Action
  [Actions.LOAD_CLAIM_INVOICES_FINALIZED](filter: IFilter) {
    InvoiceService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CLAIM_INVOICES_FINALIZED, res)
    })
  }
  @Action
  [Actions.LOAD_CLAIM_INVOICES_ALL](filter: IFilter) {
    InvoiceService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CLAIM_INVOICES_ALL, res)
    })
  }

  @Action
  [Actions.INVOICE_SEARCH](filter: ISearchFilter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    InvoiceService.search(filter).then(res => {
      this.context.commit(Mutations.SET_INVOICE_SEARCH, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  [Actions.LOAD_INVOICE](id) {
    this.context.dispatch(Actions.SET_RECENT_INVOICE, id);
    InvoiceService.get<any>(id).then(res => {
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: res?.access?.createActivities,
        viewPendingActivities: res?.access?.viewPendingActivities
      })
      this.context.commit(Mutations.SET_INVOICE, res)
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: res?.access?.uploadDocument})
      this.context.commit(Mutations.SET_CLIENT_TEMPLATE_SELECT, res?.client?.id)
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false);
    })
  }

  @Action
  [Actions.SET_RECENT_INVOICE](id) {
    const recent = CookieService.get(COOKIE_KEY.RECENT_INVOICES)
    if (!recent) {
      CookieService.save(COOKIE_KEY.RECENT_INVOICES, Array.of(id))
    } else {
      const list = JSON.parse(recent);
      if (!list.includes(id)) {
        list.push(id)
      }
      CookieService.save(COOKIE_KEY.RECENT_INVOICES, list)
    }
  }

  @Mutation
  [Mutations.SET_CLAIM_INVOICES](data: any) {
    this.claimInvoicePage = data;
  }

  @Mutation
  [Mutations.SET_CLAIM_INVOICES_FINALIZED](data: any) {
    this.claimInvoicePageFinalized = data;
  }
  @Mutation
  [Mutations.SET_CLAIM_INVOICES_ALL](data: any) {
    this.claimInvoiceAll = data;
  }

  @Mutation
  [Mutations.SET_INVOICE_SEARCH](data) {
    this.invoiceViewPage = data
  }

  @Mutation
  [Mutations.SET_INVOICE](data) {
    this.invoice = data;
  }

  @Mutation
  [Mutations.SET_LIST_INVOICES](data) {
    this.listInvoicePage = data;
  }
}