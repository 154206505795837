import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import SanctionCheckService from "@/core/services/SanctionCheckService";

@Module
export default class SanctionCheckModule extends VuexModule {
  page = {
    total: 0,
    pages: 0,
    data: [],
  }

  @Action
  [Actions.LOAD_SANCTION_CHECK](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    SanctionCheckService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_SANCTION_CHECK, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Mutation
  [Mutations.SET_SANCTION_CHECK](data) {
    this.page = data;
  }
}