import { BaseService } from "@/core/services/BaseService";

class ExchangeRateService extends BaseService<any> {
  endpoint = "/exchange-rate";

  async list() {
    return await this.http().get(this.endpoint).then(res => {
      return res.data
    })
  }

  async exchangeRate(from, to, amount) {
    return await this.http().get(this.endpoint + "/convert?from=" + from + "&to=" + to + "&amount=" + amount).then(res => {
      return res.data
    })
  }
}

export default new ExchangeRateService()