import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import DocumentService from "@/core/services/DocumentService";

@Module
export default class DocumentModule extends VuexModule {
  documentPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0
  }

  pendingPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }


  allPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }


  sentPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  receivedPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  activityDocumentPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0
  }
  document: any;
  documentUrl: any;
  access: any = {}

  @Action
  async [Actions.LOAD_DOCUMENTS](filter: any) {
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_DOCUMENTS, res);
    })
  }

  @Action
  async [Actions.LOAD_PENDING_DOCUMENTS](filter: any) {
    this.context.commit(Mutations.SET_PENDING_DOCUMENTS, {data: {total: 0, data: [], pages: 0}, loading: true});
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PENDING_DOCUMENTS, {data: res, loading: false});
    })
  }

  @Action
  async [Actions.LOAD_SENT_DOCUMENTS](filter: any) {
    this.context.commit(Mutations.SET_SENT_DOCUMENTS, {data: {total: 0, data: [], pages: 0}, loading: true});
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_SENT_DOCUMENTS, {data: res, loading: false});
    })
  }


  @Action
  async [Actions.LOAD_RECEIVED_DOCUMENTS](filter: any) {
    this.context.commit(Mutations.SET_RECEIVED_DOCUMENTS, {data: {total: 0, data: [], pages: 0}, loading: true});
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_RECEIVED_DOCUMENTS, {data: res, loading: false});
    })
  }

  @Action
  async [Actions.LOAD_ALL_DOCUMENTS](filter: any) {
    this.context.commit(Mutations.SET_ALL_DOCUMENTS, {data: {total: 0, data: [], pages: 0}, loading: true});
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ALL_DOCUMENTS, {data: res, loading: false});
    })
  }

  @Action
  async [Actions.LOAD_ACTIVITY_DOCUMENTS](filter: any) {
    await DocumentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY_DOCUMENTS, res);
    })
  }

  @Action
  async [Actions.LOAD_DOCUMENT](id) {
    await DocumentService.get(id).then(res => {
      this.context.commit(Mutations.SET_DOCUMENT, res);
    })
  }

  @Action
  async [Actions.LOAD_DOCUMENT_URL](id) {
    await DocumentService.getUrl(id).then(res => {
      this.context.commit(Mutations.SET_DOCUMENT_URL, res);
    })
  }

  @Mutation
  [Mutations.SET_DOCUMENTS](data: any) {
    this.documentPage = data
  }

  @Mutation
  [Mutations.SET_ALL_DOCUMENTS](payload: any) {
    this.allPage.loading = payload.loading;
    this.allPage.data = payload.data;
  }

  @Mutation
  [Mutations.SET_PENDING_DOCUMENTS](data: any) {
    this.pendingPage = data
  }

  @Mutation
  [Mutations.SET_RECEIVED_DOCUMENTS](data: any) {
    this.receivedPage.loading = data.loading;
    this.receivedPage.data = data.data
  }

  @Mutation
  [Mutations.SET_SENT_DOCUMENTS](data: any) {
    this.sentPage.loading = data.loading;
    this.sentPage.data = data.data;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_URL](data: any) {
    this.documentUrl = data
  }

  @Mutation
  [Mutations.SET_ACTIVITY_DOCUMENTS](data: any) {
    this.activityDocumentPage = data
  }

  @Mutation
  [Mutations.SET_DOCUMENT](data: any) {
    this.document = data
  }

  @Mutation
  [Mutations.EMIT_DOCUMENT_ACCESS](data) {
    this.access = data
  }
}