import {BaseService} from "@/core/services/BaseService";

class ChatService extends BaseService<any> {
  endpoint = "/chat"

  async load(toId, page, max) {
    return this.http().get(this.endpoint + "/load/" +  toId + "/" + page + "/" + max).then(res=>{
      return res.data;
    })
  }
  async read(contactId) {
    return this.http().get(this.endpoint + "/read/" + contactId).then(res=>{
      return res.data
    })
  }
}

export default new ChatService();