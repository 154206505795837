import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import type {IFilter} from "@/core/entity/IFilter";
import ClaimService from "@/core/services/ClaimService";
import type {ISearchFilter} from "@/core/entity/ISearchFilter";
import CookieService from "@/core/services/CookieService";
import {COOKIE_KEY} from "@/core/config/Constant";


@Module
export default class ClaimModule extends VuexModule {
  policyClaimPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  claimSearchPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  listPageClaim: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  fastTrack = {
    total: 0,
    data: [],
    pages: 0,
  }
  claim = {};

  @Action
  [Actions.LOAD_LIST_CLAIMS](filter) {
    ClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_LIST_CLAIMS, res)
    })
  }

  @Action
  [Actions.CLAIM_FAST_TRACK](filter) {
    ClaimService.fastTrack(filter).then(res => {
      this.context.commit(Mutations.SET_CLAIM_FAST_TRACK, res)
    })
  }

  @Action
  async [Actions.LOAD_POLICY_CLAIMS](filter: IFilter) {
    await ClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_CLAIMS, res)
    })
  }

  @Action
  async [Actions.CLAIM_SEARCH](filter: ISearchFilter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    await ClaimService.search(filter).then(res => {
      this.context.commit(Mutations.SET_CLAIM_SEARCH, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    });
  }

  @Action
  async [Actions.LOAD_CLAIM](id) {
    this.context.dispatch(Actions.SET_RECENT_CLAIMS, id);
    await ClaimService.get<any>(id).then(res => {
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: res?.access?.createActivities,
        viewPendingActivities: res?.access?.viewPendingActivities
      })
      this.context.commit(Mutations.EMIT_RESERVE_ACCESS, {
          updateReserve: res?.access?.updateReserve,
          editCurrentReserve: res?.access?.editCurrentReserve
        }
      )
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: res?.access?.uploadDocument})
      this.context.commit(Mutations.SET_CLAIM, res)
      this.context.commit(Mutations.SET_RESERVE, res?.reserve)
      this.context.commit(Mutations.SET_CLIENT_TEMPLATE_SELECT, res?.client?.id)
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false);
    })
  }

  @Action
  [Actions.SET_RECENT_CLAIMS](id) {
    const recent = CookieService.get(COOKIE_KEY.RECENT_CLAIMS)
    if (!recent) {
      CookieService.save(COOKIE_KEY.RECENT_CLAIMS, Array.of(id))
    } else {
      const list = JSON.parse(recent);
      if (!list.includes(id)) {
        list.push(id)
      }
      CookieService.save(COOKIE_KEY.RECENT_CLAIMS, list)
    }
  }

  @Mutation
  [Mutations.SET_CLAIM](data) {
    this.claim = data;
  }

  @Mutation
  [Mutations.SET_POLICY_CLAIMS](data) {
    this.policyClaimPage = data;
  }

  @Mutation
  [Mutations.SET_LIST_CLAIMS](data) {
    console.log(data);
    this.listPageClaim = data;
  }

  @Mutation
  [Mutations.SET_CLAIM_SEARCH](data) {
    this.claimSearchPage = data;
  }

  @Mutation
  [Mutations.SET_CLAIM_FAST_TRACK](data) {
    this.fastTrack = data;
  }
}