import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import EmailService from "@/core/services/EmailService";


@Module
export default class EmailModule extends VuexModule {
  page = {
    total: 0,
    pages: 0,
    data: [],
  }


  @Action
  [Actions.LOAD_EMAILS](filter) {
    EmailService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_EMAILS, res)
    })
  }

  @Mutation
  [Mutations.SET_EMAILS](data) {
    this.page = data;
  }
}