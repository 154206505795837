import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import LogReportService from "@/core/services/LogReportService";

@Module
export default class LogReportModule extends VuexModule {
  page: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }
  entity = <any>{};


  @Action
  [Actions.LOAD_LOG_REPORTS](filter) {
    LogReportService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_LOG_REPORTS, {loading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_LOG_REPORT](id) {
    this.context.commit(Mutations.SET_ENTITY_LOADING, true)
    LogReportService.get(id).then(res => {
      this.context.commit(Mutations.SET_LOG_REPORT, res)
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: true,
        viewPendingActivities: true
      })
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: true})
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false)
    })
  }


  @Mutation
  [Mutations.SET_LOG_REPORTS](payload) {
    this.page = payload;
  }

  @Mutation
  [Mutations.SET_LOG_REPORT](payload) {
    this.entity = payload
  }

  @Mutation
  [Mutations.SET_FILE_DATE](date) {
    this.entity.reportDate = date
  }

  @Mutation
  [Mutations.SET_FILE_NEXT_DATE](date) {
    this.entity.nextDate = date
  }

}