import {BaseService} from "@/core/services/BaseService";

class SanctionCheckService extends BaseService<any> {
  endpoint = "/sanction-check";

  async decision(id, data) {
    return this.http().put(this.endpoint + "/" + id + "/decision", data).then(res => {
      return res.data
    })
  }
}

export default new SanctionCheckService();