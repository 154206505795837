import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import TemplateService from "@/core/services/TemplateService";

@Module
export default class TemplateModule extends VuexModule {
  notePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  phonePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  emailPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  documentPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  templateCompanyPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  clientId: any = '';


  @Action
  async [Actions.LOAD_COMPANY_TEMPLATES](filter: any) {
    return await TemplateService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_COMPANY_TEMPLATES, res)
    })
  }


  @Action
  [Actions.LOAD_TEMPLATES_NOTES](filter: any) {
    TemplateService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TEMPLATE_NOTES, res)
    })
  }

  @Action
  [Actions.LOAD_TEMPLATES_PHONES](filter: any) {
    TemplateService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TEMPLATE_PHONES, res)
    })
  }

  @Action
  [Actions.LOAD_TEMPLATES_EMAILS](filter: any) {
    TemplateService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TEMPLATE_EMAILS, res)
    })
  }

  @Action
  [Actions.LOAD_TEMPLATES_DOCUMENTS](filter: any) {
    TemplateService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TEMPLATE_DOCUMENTS, res)
    })
  }

  @Mutation
  [Mutations.SET_COMPANY_TEMPLATES](data) {
    this.templateCompanyPage = data
  }

  @Mutation
  [Mutations.SET_TEMPLATE_NOTES](data) {
    this.notePage = data
  }

  @Mutation
  [Mutations.SET_TEMPLATE_PHONES](data) {
    this.phonePage = data
  }

  @Mutation
  [Mutations.SET_TEMPLATE_EMAILS](data) {
    this.emailPage = data
  }

  @Mutation
  [Mutations.SET_TEMPLATE_DOCUMENTS](data) {
    this.documentPage = data
  }

  @Mutation
  [Mutations.SET_CLIENT_TEMPLATE_SELECT](clientId) {
    this.clientId = clientId
  }
}