import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";
import {IActivity} from "@/core/entity/IActivity";

class ActivityService extends BaseService<any> {
  endpoint = ENDPOINT.ACTIVITY

  async assign(id: string, body: any) {
    return await this.http().put<IActivity>(this.endpoint + "/" + id + "/assign", body).then(res => {
      return res.data
    })
  }

  async move(id: string, body: any) {
    return await this.http().put<any>(this.endpoint + "/" + id + "/move", body).then(res => {
      return res.data
    })
  }

  async latestReview(cazeId) {
    return await this.http().get(this.endpoint + "/reviews/latest?cazeId=" + cazeId).then(res => {
      return res.data;
    })
  }

  async getTimeSpent(objectType, objectId) {
    return await this.http().get(this.endpoint + "/time/spent?objectType=" + objectType + "&objectId=" + objectId).then(res => {
      return res.data
    })
  }

  async getTranscribe(id) {
    return this.http().get(this.endpoint + "/" + id + "/transcribe").then(res => {
      return res.data
    })
  }

  async processAnalytics(id) {
    return this.http().post(this.endpoint + "/" + id + "/ai-analytics").then(res => {
      return res.data
    })
  }

  async getAnalytics(id) {
    return this.http().get(this.endpoint + "/" + id + "/ai-analytics").then(res => {
      return res.data
    })
  }
  async audit(id, data) {
    return this.http().put(this.endpoint + "/" + id + "/audit", data).then(res => {
      return res.data
    })
  }
}

export default new ActivityService();