import axios, {AxiosInstance} from "axios";
import JwtService from "@/core/services/JwtService";
import {generateSearchString, generateString, generatorQueryString} from "@/core/helpers/functions";
import {ISearchFilter} from "@/core/entity/ISearchFilter";

export abstract class BaseService<T> {
  static _axios: AxiosInstance = axios.create({
    timeout: 50000,
    baseURL: process.env.VUE_APP_API_URI
  })
  static _axios2: AxiosInstance = axios.create({
    timeout: 50000,
    baseURL: process.env.VUE_APP_API_URI
  })

  public uploadHttp(): AxiosInstance {
    BaseService._axios2.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    return BaseService._axios2;
  }

  protected http(): AxiosInstance {
    const token = JwtService.getToken()
    if (token) {
      BaseService._axios.defaults.headers.common[
        "Authorization"
        ] = `${JwtService.getToken()}`;
    }
    BaseService._axios.defaults.headers.common["Accept"] =
      "application/json";
    return BaseService._axios;
  }

  protected
  abstract endpoint: string;

  public async search<K>(filter: ISearchFilter): Promise<K> {
    const queryString = generateSearchString(filter);
    return this.http().get("/search?" + queryString).then(res => {
      return res.data
    })
  }

  public async get<T>(slug: string): Promise<T> {
    return this.http().get<T>(this.endpoint + "/" + slug).then(res => {
      return res.data
    })
  }


  public async findAll<K>(filter: any): Promise<K> {
    const queryString = generatorQueryString(filter);
    return this.http().get<K>(this.endpoint + "?" + queryString).then(res => {
      return res.data
    })
  }

  public async listFilter<K>(filter: any): Promise<K> {
    const queryString = generateString(filter);
    return this.http().get<K>(this.endpoint + "?" + queryString).then(res => {
      return res.data
    })
  }

  public async create<T>(data: any): Promise<T> {
    return this.http().post<T>(this.endpoint, data).then(res => {
      return res.data
    })
  }

  public async update<T>(id: any, data: any): Promise<T> {
    return this.http().put<T>(this.endpoint + "/" + id, data).then(res => {
      return res.data
    })
  }

  public async delete(id: string): Promise<any> {
    return this.http().delete(this.endpoint + "/" + id).then(res => {
      return res.data
    })
  }

  public async deleteReason(id: string, reason: any) {
    return this.http().delete(this.endpoint + "/" + id + "?" + generateString(reason)).then(res => {
      return res.data
    })
  }

  public async summary<T>(objectId: string, objectType: string): Promise<T> {
    return await this.http().get<T>(this.endpoint + "/summary?objectId=" + objectId + "&objectType=" + objectType).then(res => {
      return res.data;
    })
  }

}