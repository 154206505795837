import {BaseService} from "@/core/services/BaseService";

class TagService extends BaseService<any> {
  endpoint = "/tag";

  async loadTags(objectType, processType) {
    return this.http().get(this.endpoint + "/list-all/" + objectType + "/" + processType).then(res => {
      return res.data;
    })
  }

  async updateStatus(id) {
    return this.http().patch(this.endpoint + "/" + id + "/active").then(res => {
      return res.data;
    })
  }
}

export default new TagService();