import { BaseService } from "@/core/services/BaseService";

class ContentMappingService extends BaseService<any> {
  endpoint = '/content-mapping'

  public async getType(type: string): Promise<any> {
    return await this.http().get<any>(this.endpoint + "/type/" + type).then(res => {
        return res.data
      }
    )
  }
}

export default new ContentMappingService();