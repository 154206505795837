import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ContentMappingService from "@/core/services/ContentMappingService";

@Module
export default class ContentMappingModule extends VuexModule {
  page: any = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_CONTENT_MAPPINGS](filter: any) {
    ContentMappingService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CONTENT_MAPPING, res)
    })
  }

  @Mutation
  [Mutations.SET_CONTENT_MAPPING](data) {
    this.page = data;
  }
}