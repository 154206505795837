import {HCFALine} from "@/core/entity/HCFALine";

export const ENDPOINT = {
  LOGIN: '/user/login',
  VERIFY: '/user/verify',
  PROFILE: "/user/me",
  PRODUCT: "/product",
  POLICY: "/policy",
  CASE: "/case",
  LOCATION: "/location",
  COMPANY: '/company',
  USER: "/user",
  ACTIVITY: "/activity",
  NOTE: "/note",
  DOCUMENT: "/document",
  CONTACT: "/contact",
  PAYMENT: "/payment",
}


export const POLICY_STATUS = [
  {name: 'Confirmed', value: 'ACTIVE'},
  {name: 'Temporary', value: 'TEMPORARY'},
  {name: 'Not-Confirmed', value: 'NOT_CONFIRMED'},
  {name: 'Canceled', value: 'CANCELED'},
]

export const OPERATOR = {
  EQ: 'eq', // =
  LIKE: 'lk', // like
  IN: 'in',  // in
  GT: 'gt', // >
  LS: 'ls', // <
  GREQ: 'gte', // >-
  LSEQ: 'lte', // <=
  NE: 'ne', // !=
  NUL: 'null',
  NOT_NULL: 'notnull'
}

export const SEARCH_ENTITY = {
  POLICY: 'policy',
  POLICY_VIEW: 'pv',
  CASE_VIEW: 'cv',
  CASE: 'case',
  ACTIVITY: "activity",
  CLAIM_VIEW: "claimView",
}

export const CASE_TYPE = [
  {name: 'Inquiry', value: 'IQ'},
  {name: 'In-patient', value: 'IP'},
  {name: 'Out-patient', value: 'OP'},
  {name: 'TripCan', value: 'TC'},
  {name: 'Baggage', value: 'BG'},
  {name: 'Dental', value: 'DT'},
  {name: 'Other', value: 'OT'},
]

export const CASE_STATUS = [
  {name: 'Active', value: 'ACTIVE', valueName: 'Active'},
  {name: 'Closed', value: 'CLOSED', valueName: 'Closed'},
]
export const TEMPLATE_TYPE = [
  {name: 'Activity', value: 'NOTE'},
  {name: 'Task', value: 'TASK'},
  {name: 'Email', value: 'EMAIL'},
]

export const CONTACT_TYPE = [
  {name: 'Contact', value: 'CONTACT'},
  {name: 'Insured', value: 'INSURED'},
  {name: 'Home Address', value: 'HOME'},
  {name: 'Travel Address', value: 'TRAVEL'},
  {name: 'Family', value: 'FAMILY'},
  {name: 'Friend', value: 'FRIEND'},
  {name: 'Other Insurance', value: 'OTHER_INSURANCE'},
  {name: 'Provider', value: 'PROVIDER'},
]
export const PROVIDER_TYPE = [
  {name: 'Doctor', value: 'DOCTOR'},
  {name: 'Clinic', value: 'CLINIC'},
  {name: 'Hospital', value: 'HOSPITAL'},
  {name: 'Air Ambulance', value: 'AIR_AMBULANCE'},
  {name: 'Ground Ambulance', value: 'GROUND_AMBULANCE'},
  {name: 'Other', value: 'OTHER'},
]

export const INSURED_TYPE = [
  {name: 'Insured', value: 'INSURED'},
  {name: 'Primary', value: 'PRIMARY'},
  {name: 'Spouse', value: 'SPOUSE'},
  {name: 'Child', value: 'CHILD'},
  {name: 'Member', value: 'MEMBER'},
]
export const INSURED_STATUS = [
  {name: 'Active', value: 'ACTIVE'},
  {name: 'Canceled', value: 'CANCELED'},
]
export const ACTIVITY_TYPE = [
  {name: 'Phone Call (IN)', value: 'CALL_IN'},
  {name: 'Phone Call (OUT)', value: 'CALL_OUT'},
  {name: 'Reserve Update', value: 'RESERVE'},
  {name: 'Inquiry Status', value: 'INQ_ST'},
  {name: 'Medical Review', value: 'CMR'},
  {name: 'Case Review', value: 'CR'},
  {name: 'Coverage Confirmation', value: 'CC'},
  {name: 'Verification of Benefits', value: 'VB'},
  {name: 'Note ', value: 'NOTE'},
  {name: 'Email (Received) ', value: 'EMAIL_RECEIVED'},
  {name: 'Email (Sent)', value: 'EMAIL_OUT'},
]
export const ACTIVITY_STATUS = [
  {name: 'Completed', value: 'DONE'},
  {name: 'Active', value: 'ACTIVE'},
  {name: 'Pending', value: 'PENDING'},
  {name: 'Not Required', value: 'CANCELED'},
]

export const CURRENCY = [
  'CAD', 'USD', 'EUR', 'GBP', 'MXN', 'CUP', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EEK', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL', 'VES'
]


export const COOKIE_KEY = {
  RECENT_POLICIES: 'RECENT_POLICIES',
  RECENT_CASES: 'RECENT_CASES',
  RECENT_CLAIMS: 'RECENT_CLAIMS',
  RECENT_INVOICES: "RECENT_INVOICES",
  RECENT_PAYMENTS: "RECENT_PAYMENTS",
  THEME: "THEME",
  IMPERSONAL: "IMPERSONAL"
}

export const PROVIDER_STATUS = [
  {name: 'Active', value: 'ACTIVE'},
  {name: 'Completed', value: 'COMPLETED'},
]

export const UB04_LINE = {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0};
export const UB04_LINES = [
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
  {type: 'UB04', rev: '', desc: '', cpt: '', modifier: '', fromDt: NaN, units: 0, amount: 0},
]
export const PRESCRIPT = {type: 'PRESCRIPT'}
export const TRIP_CAN = {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0}
export const TRIP_CANS = [
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
  {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0},
]
export const LOST_BAG = {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''}

export const LOST_BAGS = [
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
  {type: 'LOST_BAG', desc: '', pos: '', fromDt: NaN, amount: 0, currency: ''},
]

export const HCFA_LINE = {
  type: 'HCFA',
  fromDt: NaN,
  toDt: NaN,
  pos: '',
  emg: '',
  cpt: '',
  modifier: '',
  diagnosis: '',
  units: 0,
  amount: 0
};

export const RESERVE_LINE_TYPE = [
  {name: 'Hospital', value: 'HOSPITAL'},
  {name: 'Physician', value: 'PHYSICIAN'},
  {name: 'Clinic', value: 'CLINIC'},
  {name: 'Other Expenses', value: 'OTHER_EXPENSES'},
]

export const RESERVE_LINES = [{
  type: '',
  currency: '',
  value: 0.0,
  valueCAD: 0.0
}]

export const HCFA_LINES = [
  new HCFALine(),
]

export const DOCUMENT_TYPES = [
  {value: 'LETTER', name: "LETTER"},
  {value: 'AUDIO', name: "AUDIO"},
  {value: 'VOB', name: "VOB"},
  {value: 'MR', name: "Medical Records"},
  {value: 'CN', name: "Clinical Notes"},
  {value: 'DS', name: "Discharge Summary"},
  {value: 'OR', name: "Operative Report"},
  {value: 'CA', name: "Collection Agency"},
  {value: 'LL', name: "Lawyer Letter"},
  {value: 'OI', name: "Other Insurance"},
  {value: 'RI', name: "Repricing Invoice"},
  {value: 'EOB', name: "EOB"},
  {value: 'PAYMENT_BATCH', name: "BATCH"},
  {value: 'O_INVOICE', name: "Original Invoice"},
  {value: 'STATEMENT', name: "Statement"},
  {value: 'CLAIM_FORM', name: "Claim Form"},
  {value: 'PLANE_TICKET', name: "Plane Ticket"},
  {value: 'POLICE_REPORT', name: "Police Report"},
  {value: 'PIR', name: "Property Irregularity Report"},
  {value: 'AUTH', name: "Authorization"},
  {value: 'AA', name: "Air Ambulance"},
  {value: 'CR', name: "Commercial Repatriation"},
  {value: 'PASSPORT', name: "Passport"},
  {value: 'PREEX', name: "Pre-Ex"},
  {value: 'POLICY', name: "Policy Wording"},
  {value: 'MED_DECLARATION', name: "Medical Declaration"},
  {value: 'APPLICATION', name: "Application (Insurance)"},
  {value: 'LAST_WILL', name: "Last Will"},
  {value: 'POA', name: "Power of Attorney"},
  {value: 'TPL', name: "Third Party Liability"},
  {value: 'KEYFACTS', name: "Keyfacts"},
  {value: 'MVA', name: "Motor Vehicle Accident Benefit/Policy"},
  {value: 'POD', name: "Proof of Departure"},
  {value: 'PCPS', name: "Primary Care Physician - GP"},
  {value: 'CL', name: "Clarifying Letter - PCP"},
  {value: 'SPR', name: "Specialists Records"},
  {value: 'RX', name: "Prescriptions"},
  {value: 'APPEAL', name: "Appeal Letter"},
  {value: 'LEGAL', name: "Legal Letter"},
  {value: 'FTF', name: "Fit to Fly"},
  {value: 'MEDIF', name: "MEDIF"},
  {value: 'LOBL', name: "Limitation of Benefits"},
  {value: 'HIPAA', name: "HIPAA"},
  {value: 'PIPEDA', name: "PIPEDA"},
  {value: 'RECS', name: "Receipt"},
  {value: 'MRF', name: "Medical Release Form"},
  {value: 'DISCL', name: "Disclaimer"},
  {value: 'QUOTE', name: "Quote"},
  {value: 'DEATH_CERT', name: "Death Certificate"},
  {value: 'COB', name: "COB Letter"},
  {value: 'CHEQUE', name: "Cheque"},
  {value: 'NR', name: "Network Repricing"},
  {value: 'MRR', name: "Medical Records Request"},
  {value: 'IR', name: "Invoice Request"},
  {value: 'DED', name: "Deductible"},
  {value: 'COI', name: "Confirmation of Insurance"},
  {value: 'RPS', name: "Repricing Sheet"},
  {value: 'GOP', name: "GOP"},
  {value: 'PROGRESS_NOTE', name: "Progress Note"},
  {value: 'POP', name: "Proof of Payment"},
  {value: 'GHIPF', name: "GHIP Forms"},
  {value: 'ARPT', name: "Accident Report"},
]
export const DOCUMENT_STATUS = [
  {name: 'Received', value: 'RECEIVED'},
  {name: 'Sent', value: 'SENT'},
]

export const PRODUCT_TYPE = [
  {name: 'Group', value: 'GROUP'},
  {name: 'Travel', value: 'TRAVEL'},
]

export const BACKGROUNDS = [
  '/media/backgrounds/1.jpeg',
  '/media/backgrounds/2.jpeg',
  '/media/backgrounds/3.jpeg',
  '/media/backgrounds/4.jpeg',
  '/media/backgrounds/5.jpeg',
  '/media/backgrounds/6.jpeg',
  '/media/backgrounds/7.jpeg',
  '/media/backgrounds/8.jpeg',
  '/media/backgrounds/9.jpeg',
  '/media/backgrounds/10.jpeg',
  '/media/backgrounds/11.png',
  '/media/backgrounds/12.jpeg',
  '/media/backgrounds/13.jpeg',
  '/media/backgrounds/14.jpeg',
  '/media/backgrounds/15.jpeg',
  '/media/backgrounds/16.jpeg',
  '/media/backgrounds/17.jpeg',
  '/media/backgrounds/18.jpeg',
  '/media/backgrounds/19.jpeg',
  '/media/backgrounds/20.jpeg',
]

export const THEME_DEFAULT = [
  {name: 'Light', value: 'rgba(59, 146, 167, 0.1)'},
  {name: 'Dark', value: '#296472'},
]


export enum FieldType {
  TEXT,
  CHECKBOX,
  NUMBER,
  DATETIME,
  SELECT,
  RADIO,
  GROUP,
  INPUT_GROUP,
  TEXTAREA,
}

export enum InvoiceType {
  HCFA = 'HCFA',
  UB04 = 'UB04',
  TRIP_CAN = 'TRIP_CAN',
  PRESCRIPT = 'PRESCRIPT',
  LOST_BAG = 'LOST_BAG',
  DENTAL = 'DENTAL',
  STATEMENT = 'STATEMENT',
  RX = 'RX',
}

export enum GroupField {
  INPUT_GROUP,
  PROVIDER,
  LINES
}

export enum LookupType {
  CPT = "CPT",
  HCPCS = "HCPCS",
  ICD10CM = "ICD10CM",
  ICD10PCS = "ICD10PCS",
  POS = "POS",
  REV = "REV",
  CPTHCPCS = "CPTHCPCS"
}


export const REPRICE_STATUS = [
  {value: 'TO_REPRICE', name: 'To Reprice'},
  {value: 'NOT_REPRICEABLE', name: 'Not-Repriceable'},
  {value: 'TO_SEND', name: 'To send'},
  {value: 'IN_PROCESS', name: 'In process'},
  {value: 'REPRICED', name: 'Repriced'},
]


export const ADJUDICATE_STATUS = [
  {value: 'TO_ADJUDICATE', name: 'To Adjudicate'},
  {value: 'PENDING', name: 'Pending'},
  {value: 'NONE_PAYABLE', name: 'Denied'},
  {value: 'INELIGIBLE', name: 'Ineligible'},
  {value: 'ADJUDICATED', name: 'Payable'},
]


export const ADJUDICATE_TYPE = [
  {value: 'INELIGIBLE', name: 'Ineligible'},
  {value: 'DEDUCTIBLE', name: 'Deductible'},
  {value: 'COINSURANCE', name: 'Coinsurance'},
  {value: 'COPAY', name: 'Copay'},
  {value: 'OTHER_INSURANCE', name: 'Other Insurance'},
  {value: 'CLIENT_RESPONSIBILITY', name: 'Client Responsibility'},
  {value: 'CLIENT_PAID', name: 'Client Paid'},
]


export const PAYMENT_SUB_TYPE = [
  {value: 'PROVIDER', name: 'Provider'},
  {value: 'PATIENT', name: 'Patient'},
  {value: 'OTHER', name: 'Other'}
]


export const PAYMENT_METHOD = [
  {value: 'NA', name: 'NA'},
  {value: 'WIRE_TRANSFER', name: 'Wire Transfer'},
  {value: 'CHEQUE', name: 'Cheque'},
  {value: 'DIRECT_DEPOSIT', name: 'Direct Deposit'},
  {value: 'TRANSFER', name: 'Transfer'}
]

export const PAYMENT_STATUS = [
  {value: 'PENDING', name: 'Pending'},
  {value: 'PAYABLE', name: 'Payable'},
  {value: 'PAID', name: 'Paid'},
]

export const PAYMENT_STATUS_PENDING = [
  {value: 'PENDING', name: 'Pending'},
]
export const NOTIFICATION_CODE = [
  {value: 'CASE_CREATE', name: 'Case Created'},
  {value: 'CASE_UPDATE', name: 'Case Update'},
  {value: 'CASE_DELETE', name: 'Case Delete'},
  {value: 'CASE_CLOSED', name: 'Case Closed'},
  {value: 'CASE_REOPEN', name: 'Case Reopen'},
  {value: 'CLAIM_CREATE', name: 'Claim Create'},
  {value: 'CLAIM_UPDATE', name: 'Claim Update'},
  {value: 'CLAIM_CLOSED', name: 'Claim Closed'},
  {value: 'CLAIM_DELETE', name: 'Claim Closed'},
  {value: 'INVOICE_CREATE', name: 'Invoice Create'},
  {value: 'INVOICE_UPDATE', name: 'Invoice Update'},
  {value: 'INVOICE_DELETE', name: 'Invoice Delete'},
  {value: 'PAYMENT_CREATE', name: 'Payment Create'},
  {value: 'PAYMENT_UPDATE', name: 'Payment Update'},
  {value: 'PAYMENT_DELETE', name: 'Payment Delete'},
]

export const NOTIFICATION_TYPE = [
  {value: 'EMAIL', name: 'EMAIL'},
  {value: 'SMS', name: 'SMS'},
  {value: 'BOTH', name: 'Both'},
]

export const BENEFIT_TYPE = [
  {value: 'M', name: 'Medical'},
  {value: 'EH', name: 'Extended health'},
  {value: 'ERH', name: 'Emergency return home'},
  {value: 'TI', name: 'Trip interruption'},
  {value: 'TC', name: 'Trip Cancellation'},
  {value: 'RR', name: 'Repatriation of remains'},
  {value: 'L', name: 'Life'},
  {value: 'DM', name: 'Dismemberment'},
]


export const CM_STATUS = [
  {value: 'ACTIVE', name: 'Active'},
  {value: 'CLOSED', name: 'Closed'},
  {value: 'NOT_REQUIRED', name: 'Not Required'},
]

export const PRIORITY = [
  {value: 'LOW', name: 'Low'},
  {value: 'MEDIUM', name: 'Medium'},
  {value: 'HIGH', name: 'High'},
  {value: 'HIGHEST', name: 'Highest'},
]

export const CLAIM_DOC_STATUS = [
  {name: 'Not Requested', value: 'NOT_REQUESTED'},
  {name: 'Pending', value: 'PENDING'},
  {name: 'Completed', value: 'COMPLETED'},
  {name: 'Not Provided', value: 'NOT_PROVIDED'},
]

export const REMINDER_STATUS = [
  {name: 'Not Set', value: 'NOT_SET'},
  {name: 'Completed', value: 'COMPLETED'},
]

export const CLAIM_DECISION = [
  {name: 'No Decision', value: 'NO_DECISION'},
  {name: 'Payable', value: 'PAYABLE'},
  {name: 'Denied', value: 'DENIED'}
]
export const CLAIM_APPROVAL = [
  {name: 'Not Required', value: 'NOT_REQUIRED'},
  {name: 'Requested', value: 'REQUESTED'},
  {name: 'Approved', value: 'APPROVED'},
  {name: 'Not Approved', value: 'NOT_APPROVED'},
]

export const TAG_COLOR = [
  {name: 'red', hex: "#dc3545"},
  {name: 'blue', hex: "#0d6efd"},
  {name: 'purple', hex: "#6f42c1"},
  {name: 'pink', hex: "#d63384"},
  {name: 'orange', hex: "#fd7e14"},
  {name: 'yellow', hex: "#ffc107"},
  {name: 'green', hex: "#198754"},
  {name: 'teal', hex: "#20c997"},
  {name: 'cyan', hex: "#0dcaf0"},
]

export const PROCESS_TYPE = {
  MEDICAL_REVIEW: {name: 'Medical Review', names: 'Medical Reviews'},
  COMPLAINT: {name: 'Complaint', names: 'Complaints'},
  INQUIRY: {name: 'Inquiry', names: 'Inquiries'},
  APPEAL: {name: 'Appeal', names: 'Appeals'},
  ISSUE: {name: 'Issue', names: 'Issues'},
  ESCALATION: {name: 'Escalation', names: 'Escalations'},
}

export const SERVICE_PAYMENT_STATUS = [
  {value: 'PENDING', name: 'Pending'},
  {value: 'RECEIVABLE', name: 'Receivable'},
  {value: 'RECEIVED', name: 'Received'},
]

export const PERIOD = [
  {value: 'THIS_WEEK', name: 'This Week'},
  {value: 'THIS_MONTH', name: 'This Month'},
  {value: 'THIS_YEAR', name: 'This Year'},
  {value: 'CUSTOM', name: 'Custom'},
]

export const GHIP_PROVINCES = [
  {name: 'Alberta'},
  {name: 'British Columbia'},
  {name: 'Manitoba'},
  {name: 'New Brunswick'},
  {name: 'Newfoundland and Labrador'},
  {name: 'Northwest Territories'},
  {name: 'Nova Scotia'},
  {name: 'Nunavut'},
  {name: 'Ontario'},
  {name: 'Prince Edward Island'},
  {name: 'Quebec'},
  {name: 'Saskatchewan'},
  {name: 'Yukon'},
]


export const CALL_CATEGORY = [
  {value: 'PRE_TRIP', name: 'Pre-Trip'},
  {value: 'DURING_TRIP', name: 'During-Trip'},
  {value: 'POST_TRIP', name: 'Post-Trip'},
  {value: 'INQUIRY', name: 'Claim Inquiry'},
]

export const ChartCode = {
  CALLS_IN_TILE: 'CALLS_IN_TILE',
  CALLS_OUT_TILE: 'CALLS_OUT_TILE',
  CALLS_DUR_TILE: 'CALLS_DUR_TILE',
  CALLS_AUDIT_TILE: 'CALLS_AUDIT_TILE',
  CALLS_CHART: 'CALLS_CHART',
  CALLS_HOUR_CHART: 'CALLS_HOUR_CHART',
  CALLS_MONTH_CHART: 'CALLS_MONTH_CHART',
  CALLS_DUR_CHART: 'CALLS_DUR_CHART',
  CALLS_DUR_HOUR_CHART: 'CALLS_DUR_HOUR_CHART',
  CALLS_CLIENT_SUMMARY_CHART: 'CALLS_CLIENT_SUMMARY_CHART',
  CALLS_CLIENT_CHART: 'CALLS_CLIENT_CHART',
}

export const ChartType = {
  TILE: 'tile',
  PIE: 'pie',
  COLUMN: 'column',
}