import { BaseService } from "@/core/services/BaseService";

class PageService extends BaseService<any> {
  endpoint = '/doc';

  async menu(key) {
    return await this.http().get(this.endpoint + "/menu/" + key).then(res => {
      return res.data;
    })
  }

  async docs(key) {
    return await this.http().get(this.endpoint + "/templates/" + key).then(res => {
      return res.data
    })
  }
  async getByKey(key) {
    return await this.http().get(this.endpoint + "/doc/" + key).then(res => {
      return res.data
    })
  }

  async sections(id) {
    return await this.http().get(this.endpoint + "/" + id + "/sections").then(res => {
      return res.data
    })
  }

  async files(id) {
    return await this.http().get(this.endpoint + "/" + id + "/files").then(res => {
      return res.data
    })
  }

  async download(id) {
    return await this.http().get(this.endpoint + "/files/" + id + "/download",
      {responseType: "blob"}).then(res => {
      return res;
    })
  }

}


export default new PageService();