import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import  PermissionService from "@/core/services/PermissionService";
import type { IUserPermission } from "@/core/entity/IUserPermission";

@Module
export default class PermissionModule extends VuexModule {
  permissions: IUserPermission[] = []

  @Action
  async [Actions.LOAD_PERMISSIONS]() {
  }


}