import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import PaymentService from "@/core/services/PaymentService";
import { ITableResponse } from "@/core/entity/ITableResponse";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY } from "@/core/config/Constant";

@Module
export default class PaymentModule extends VuexModule {
  paymentSearchPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  paymentPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  @Action
  [Actions.SET_RECENT_PAYMENT](id) {
    const recent = CookieService.get(COOKIE_KEY.RECENT_PAYMENTS)
    if (!recent) {
      CookieService.save(COOKIE_KEY.RECENT_PAYMENTS, Array.of(id))
    } else {
      const list = JSON.parse(recent);
      if (!list.includes(id)) {
        list.push(id)
      }
      CookieService.save(COOKIE_KEY.RECENT_PAYMENTS, list)
    }
  }

  @Action
  async [Actions.SEARCH_PAYMENT](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    await PaymentService.search(filter).then(res => {
      this.context.commit(Mutations.SET_SEARCH_PAYMENT, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    });
  }

  @Action
  async [Actions.LOAD_PAYMENTS](filter: any) {
    await PaymentService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PAYMENTS, {loading: false, data: res})
    })
  }

  @Mutation
  [Mutations.SET_PAYMENTS](payload: any) {
    this.paymentPage.loading = payload.loading;
    this.paymentPage.data = payload.data;
  }

  @Mutation
  [Mutations.SET_SEARCH_PAYMENT](data) {
    this.paymentSearchPage = data;
  }


}

