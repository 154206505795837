import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import {Client, IMessage} from '@stomp/stompjs';

@Module
export default class WebSocketModule extends VuexModule {
  client: Client = new Client();
  clientConnected = false

  @Mutation
  async [Mutations.WS_CONNECT]() {
    this.client = new Client({
      brokerURL: process.env.VUE_APP_API_WS,
      connectHeaders: {
        "Authorization": `${JwtService.getToken()}`,
      },
      reconnectDelay: 5000
    });
    console.log("load WS_CONNECT connect")
    this.client.activate();

    console.log("load WS_CONNECT connected")
    this.clientConnected = true;
  }

  @Mutation
  [Mutations.WS_SUB_TOPIC](topic: string) {
    this.client.subscribe(topic, (data: IMessage) => {

    })
  }

  @Mutation
  [Mutations.WS_UN_SUB_TOPIC](topic: string) {
    this.client.unsubscribe(topic);
  }

  @Mutation
  [Mutations.WS_SEND](payload) {
    console.log(payload)
    this.client.publish({destination: payload.destination, body: payload.data})
  }
}

