import {BaseService} from "@/core/services/BaseService";

class TaskService extends BaseService<any> {
  endpoint = '/task';

  async complete(id, model) {
    return await this.http().put(this.endpoint + "/" + id + "/complete", model).then(res => {
      return res.data
    })
  }

  async assignToCurrent(id) {
    return await this.http().patch(this.endpoint + "/"+id+"/assign").then(res=>{
      return res.data
    })
  }
}

export default new TaskService()