import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import type {IProduct} from "@/core/entity/IProduct";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import type {IFilter} from "@/core/entity/IFilter";
import ProductService from "@/core/services/ProductService";


@Module
export default class ProductModule extends VuexModule {
  productPage: ITableResponse<IProduct> = {
    total: 0,
    pages: 0,
    data: [],
  }
  product: any = {};
  isLoading: boolean = false;

  @Action
  async [Actions.LOAD_PRODUCTS](filter: IFilter) {
    const page = await ProductService.findAll<ITableResponse<IProduct>>(filter)
    this.context.commit(Mutations.SET_PRODUCTS, page)
  }

  @Action
  async [Actions.LOAD_PRODUCT](uuid: string) {
    const product = await ProductService.get<any>(uuid);
    this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: product.access.manage})
    this.context.commit(Mutations.SET_PRODUCT, product)
  }

  @Mutation
  [Mutations.SET_PRODUCTS](page: ITableResponse<IProduct>) {
    this.productPage = page;
  }

  @Mutation
  [Mutations.SET_PRODUCT](product: IProduct) {
    this.product = product;
  }
}