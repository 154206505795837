import { BaseService } from "@/core/services/BaseService";


class LookupService extends BaseService<any> {
  endpoint = "/lookup"

  async searchCode(type: string, code: string) {
    return await this.http().get(this.endpoint + "/search?type=" + type + "&code=" + code).then(res => {
      return res.data
    })
  }

  async checkCode(type: string, code: string) {
    return await this.http().get(this.endpoint + "/check?type=" + type + "&code=" + code).then(res => {
      return res.data
    })
  }
}

export default new LookupService();