import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import DashboardService from "@/core/services/DashboardService";
import ActivityService from "@/core/services/ActivityService";

@Module
export default class DashboardModule extends VuexModule {
  caseCreatedChart: any = {
    isLoading: true,
    chart: {}
  }
  claimCreatedChart: any = {
    isLoading: true,
    chart: {}
  }

  invoiceCreatedChart: any = {
    isLoading: true,
    chart: {},
  }

  paymentCreatedChart: any = {
    isLoading: true,
    chart: {},
  }


  @Action
  [Actions.LOAD_CHART_CREATED_CLAIM](payload: any) {
    DashboardService.claimCreated(payload.filter).then(res => {
      this.context.commit(Mutations.SET_CHART_CREATED_CLAIM, {isLoading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_CHART_CREATED_INVOICE](payload: any) {
    DashboardService.invoiceCreated(payload.filter).then(res => {
      this.context.commit(Mutations.SET_CHART_CREATED_INVOICE, {isLoading: false, data: res})
    })
  }


  @Action
  [Actions.LOAD_CHART_CREATED_PAYMENT](payload: any) {
    DashboardService.paymentCreated(payload.filter).then(res => {
      this.context.commit(Mutations.SET_CHART_CREATED_PAYMENT, {isLoading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_CHART_CREATED_CASE](payload: any) {
    DashboardService.caseCreated(payload.filter).then(res => {
      this.context.commit(Mutations.SET_CHART_CREATED_CASE, {isLoading: false, data: res})
    })
  }

  @Mutation
  [Mutations.SET_CHART_CREATED_CLAIM](payload) {
    this.claimCreatedChart.isLoading = payload.isLoading;
    this.claimCreatedChart.chart = payload.data;
  }


  @Mutation
  [Mutations.SET_CHART_CREATED_INVOICE](payload) {
    this.invoiceCreatedChart.isLoading = payload.isLoading;
    this.invoiceCreatedChart.chart = payload.data;
  }

  @Mutation
  [Mutations.SET_CHART_CREATED_CASE](payload) {
    this.caseCreatedChart.isLoading = payload.isLoading;
    this.caseCreatedChart.chart = payload.data;
  }

  @Mutation
  [Mutations.SET_CHART_CREATED_PAYMENT](payload) {
    this.paymentCreatedChart.isLoading = payload.isLoading;
    this.paymentCreatedChart.chart = payload.data;
  }
}