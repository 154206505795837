import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ITableResponse } from "@/core/entity/ITableResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { IFilter } from "@/core/entity/IFilter";
import AuditService from "@/core/services/AuditService";

@Module
export default class AuditModule extends VuexModule {
  auditPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_AUDITS](filter: IFilter) {
    AuditService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_AUDITS, res)
    })
  }

  @Mutation
  [Mutations.SET_AUDITS](data) {
    this.auditPage = data;
  }

}