import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class LoadingModule extends VuexModule {
  searchLoading: boolean = false;
  entityLoading: boolean = false;
  @Mutation
  [Mutations.SET_SEARCH_LOADING](isLoading: boolean) {
    this.searchLoading = isLoading;
  }

  @Mutation
  [Mutations.SET_ENTITY_LOADING] (isLoading: boolean) {
    this.entityLoading = isLoading;
  }
}