import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ITableResponse } from "@/core/entity/ITableResponse";
import type { IPolicyView } from "@/core/entity/IPolicyView";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import PolicyService from "@/core/services/PolicyService";
import type { ISearchFilter } from "@/core/entity/ISearchFilter";
import type { IPolicy } from "@/core/entity/IPolicy";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY } from "@/core/config/Constant";
import CaseService from "@/core/services/CaseService";
import ClaimService from "@/core/services/ClaimService";


@Module
export default class PolicyModule extends VuexModule {
  isPageLoading = true;
  policyViewPage: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  }

  watchingPage: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  }
  policy: any;
  cases: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  };

  activeCases: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  };
  closedCases: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  };

  activeClaims: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  };

  closedClaims: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  };


  @Action
  [Actions.LOAD_WATCHING_POLICIES](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    PolicyService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_WATCHING_POLICIES, res)
    }).finally(()=>{
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  async [Actions.LOAD_POLICY](id) {
    this.context.dispatch(Actions.SET_RECENT_POLICY, id)
    await PolicyService.get<any>(id).then(res => {
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: res?.access?.createActivities,
        viewPendingActivities: res?.access?.viewPendingActivities
      })
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: res?.access?.uploadDocument})
      this.context.commit(Mutations.SET_POLICY, res)
      this.context.commit(Mutations.SET_CLIENT_TEMPLATE_SELECT, res?.client?.id)
    }).finally(()=>{
      this.context.commit(Mutations.SET_ENTITY_LOADING, false);
    })
  }

  @Action
  async [Actions.LOAD_POLICY_CASES](filter) {
    await CaseService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_CASES, res)
    })
  }

  @Action
  async [Actions.LOAD_POLICY_ACTIVE_CLAIMS](filter) {
    await ClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_ACTIVE_CLAIMS, res)
    })
  }

  @Action
  async [Actions.LOAD_POLICY_CLOSED_CLAIMS](filter) {
    await ClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_CLOSED_CLAIMS, res)
    })
  }

  @Action
  async [Actions.LOAD_POLICY_ACTIVE_CASES](filter) {
    await CaseService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_ACTIVE_CASES, res)
    })
  }


  @Action
  async [Actions.LOAD_POLICY_CLOSED_CASES](filter) {
    await CaseService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_CLOSED_CASES, res)
    })
  }

  @Action
  [Actions.SET_RECENT_POLICY](id) {
    const recentPolicies = CookieService.get(COOKIE_KEY.RECENT_POLICIES)
    if (!recentPolicies) {
      CookieService.save(COOKIE_KEY.RECENT_POLICIES, Array.of(id))
    } else {
      const list = JSON.parse(recentPolicies);
      if (!list.includes(id)) {
        list.push(id)
      }
      CookieService.save(COOKIE_KEY.RECENT_POLICIES, list)
    }
  }


  @Action
  [Actions.POLICY_SEARCH](filter: ISearchFilter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    PolicyService.search<ITableResponse<IPolicyView>>(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_SEARCH, res);
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Mutation
  [Mutations.SET_POLICY_ACTIVE_CASES](data) {
    this.activeCases = data;
  }

  @Mutation
  [Mutations.SET_POLICY_ACTIVE_CLAIMS](data) {
    this.activeClaims = data;
  }

  @Mutation
  [Mutations.SET_POLICY_CLOSED_CLAIMS](data) {
    this.closedClaims = data;
  }

  @Mutation
  [Mutations.SET_POLICY_CLOSED_CASES](data) {
    this.closedCases = data;
  }

  @Mutation
  [Mutations.SET_POLICY_SEARCH](data: any) {
    this.policyViewPage = data;
  }

  @Mutation
  [Mutations.SET_POLICY_CASES](data: any) {
    this.cases = data;
  }

  @Mutation
  [Mutations.SET_WATCHING_POLICIES](data) {
    this.watchingPage = data;
  }

  @Mutation
  [Mutations.SET_POLICY](data: IPolicy) {
    this.policy = data;
  }
}