import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import QuicksightService from "@/core/services/QuicksightService";

@Module
export default class QuicksightModule extends VuexModule {
  page: any = {
    total: 0,
    data: [],
    pages: 0,
  }

  menu: any = [];

  @Action
  [Actions.LOAD_QUICKSIGHTS_MENU]() {
    QuicksightService.menu().then(res => {
      this.context.commit(Mutations.SET_QUICKSIGHTS_MENU, res)
    })
  }

  @Action
  [Actions.LOAD_QUICKSIGHTS](filter: any) {
    QuicksightService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_QUICKSIGHTS, res)
    })
  }

  @Mutation
  [Mutations.SET_QUICKSIGHTS](data) {
    this.page = data;
  }

  @Mutation
  [Mutations.SET_QUICKSIGHTS_MENU](data) {
    this.menu = data;
  }
}