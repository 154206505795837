import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ScanService from "@/core/services/ScanService";

@Module
export default class ScanModule extends VuexModule {
  page: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.LOAD_SCANS](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ScanService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_SCANS, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Mutation
  [Mutations.SET_SCANS](data) {
    this.page = data;
  }
}