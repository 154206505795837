import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import NotificationService from "@/core/services/NotificationService";

@Module
export default class NotificationModule extends VuexModule {
  page: any = {
    data: [],
    total: 0,
    totalPages: 0
  }

  @Action
  [Actions.LOAD_NOTIFICATIONS](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    NotificationService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_NOTIFICATIONS, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }


  @Mutation
  [Mutations.SET_NOTIFICATIONS](data) {
    this.page = data
  }
}