import ApiService from "@/core/services/ApiService";
import { ENDPOINT } from "@/core/config/Constant";
import { ILoginResponse } from "@/core/response/ILoginResponse";

class AuthService {
  login(login: any) {
    return ApiService.vueInstance.axios.post<ILoginResponse>(ENDPOINT.LOGIN, login).then(res => {
      return res.data
    }).catch(error => {
      throw error;
    })
  }

  verify(login: any) {
    return ApiService.vueInstance.axios.post<ILoginResponse>(ENDPOINT.VERIFY, login).then(res => {
      return res.data
    }).catch(error => {
      throw error;
    })
  }

  async profile() {
    return await ApiService.vueInstance.axios.get<any>(ENDPOINT.PROFILE).then(res => {
      return res.data
    })
  }
}

export default new AuthService()