import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import CheckListService from "@/core/services/CheckListService";

@Module
export default class CheckListModule extends VuexModule {
  checkLists: any = [];

  @Action
  [Actions.LOAD_CHECK_LISTS](filter: any) {
    CheckListService.list(filter).then(res => {
      this.context.commit(Mutations.SET_CHECK_LISTS, res);
    })
  }

  @Mutation
  [Mutations.SET_CHECK_LISTS](data) {
    this.checkLists = data.reverse();
  }
}
