import { BaseService } from "@/core/services/BaseService";

class EntityMetaService extends BaseService<any> {
  endpoint = "/entity-meta"

  async getMeta(objectId: string, objectType: string, type: String) {
    return await this.http().get(this.endpoint + "/watch?objectId=" + objectId + "&objectType=" + objectType + "&type=" + type).then(res => {
      return res.data
    })
  }
}

export default new EntityMetaService();