import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import LookupService from "@/core/services/LookupService";

@Module
export default class LookupModule extends VuexModule {
  searchData: any;
  searching = false

  page: any = {
    data: [],
    total: 0,
    totalPages: 0
  }

  @Action
  [Actions.LOOKUP_SEARCH](term) {
    this.context.commit('lockupSearching', true);
    LookupService.searchCode(term.type, term.code).then(res => {
      this.context.commit(Mutations.SET_LOOKUP_SEARCH, res);
    })
  }

  @Action
  [Actions.LOAD_LOOKUPS](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    LookupService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_LOAD_LOOKUPS, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Mutation
  ['lockupSearching'](searching: boolean) {
    this.searching = searching;
  }

  @Mutation
  [Mutations.SET_LOOKUP_SEARCH](data) {
    this.searchData = data;
    this.searching = false;
  }

  @Mutation
  [Mutations.SET_LOAD_LOOKUPS](data) {
    this.page = data
  }
}