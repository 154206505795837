import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import PolicySearchService from "@/core/services/PolicySearchService";

@Module
export default class PolicyIntegrationModule extends VuexModule {
  page: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: [],
  }

  @Action
  async [Actions.LOAD_POLICY_INTEGRATION_SEARCH](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    PolicySearchService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_POLICY_INTEGRATION_SEARCH, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Mutation
  [Mutations.SET_POLICY_INTEGRATION_SEARCH](data) {
    this.page = data;
  }
}