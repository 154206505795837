import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import EntityMetaService from "@/core/services/EntityMetaService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class EntityMetaModule extends VuexModule {
  entityWatching = {
    objectType: '',
    metaEntity: {}
  }

  @Action
  [Actions.LOAD_WATCHING_ENTITY](payload) {
    EntityMetaService.getMeta(payload.objectId, payload.objectType, payload.type).then(res => {
      this.context.commit(Mutations.SET_WATCHING_ENTITY, {objectType: payload.objectType, metaEntity: res})
    })
  }

  @Mutation
  [Mutations.SET_WATCHING_ENTITY](data) {
    this.entityWatching.objectType = data.objectType
    this.entityWatching.metaEntity = data.metaEntity
  }
}