import {BaseService} from "@/core/services/BaseService";
import {generatorQueryString} from "@/core/helpers/functions";


class ActionTemplateService extends BaseService<any> {
  endpoint = "/action-template"

  async listAll(filter: any): Promise<any> {
    const queryString = generatorQueryString(filter);
    return this.http().get<any>(this.endpoint + "/all?" + queryString).then(res => {
      return res.data
    })
  }

  async list(area) {
    return await this.http().get(this.endpoint + "?area=" + area).then(res => {
      return res.data
    })
  }

  async getByCode(code) {
    return await this.http().get(this.endpoint + "/code/" + code).then(res => {
      return res.data
    })
  }
}

export default new ActionTemplateService();