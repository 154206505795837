import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import type {IActivity} from "@/core/entity/IActivity";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import type {ISearchFilter} from "@/core/entity/ISearchFilter";
import ActivityService from "@/core/services/ActivityService";
import CaseService from "@/core/services/CaseService";
import type {IFilter} from "@/core/entity/IFilter";

@Module
export default class ActivityModule extends VuexModule {
  searchPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  latestReview = {};
  pendingActivities: IActivity[] = [];
  activities: IActivity[] = [];
  activityPage: ITableResponse<IActivity> = {
    total: 0,
    data: [],
    pages: 0
  }
  viewAccess: any = {};
  activity = {} as IActivity;
  pendingPage: any = {
    loading: true,
    data:
      {
        total: 0,
        data: [],
        pages: 0
      }
  }

  completePage: any = {
    loading: true,
    data:
      {
        total: 0,
        data: [],
        pages: 0
      }
  }

  allPage: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0
    }
  }

  @Action
  async [Actions.ACTIVITY_LATEST_REVIEWS](cazeId: any) {
    ActivityService.latestReview(cazeId).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY_LATEST_REVIEWS, res);
    })
  }

  @Action
  async [Actions.ACTIVITY_SEARCH_PAGE](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    await ActivityService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY_SEARCH_PAGE, res);
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  async [Actions.LOAD_PENDING_ACTIVITIES](filter: IFilter) {
    this.context.commit(Mutations.SET_PENDING_ACTIVITIES, {loading: true, data: {total: 0, data: [], pages: 0}})
    ActivityService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PENDING_ACTIVITIES, {loading: false, data: res,})
    })
  }

  @Action
  async [Actions.LOAD_COMPLETE_ACTIVITIES](filter: IFilter) {
    this.context.commit(Mutations.SET_COMPLETE_ACTIVITIES, {data: {total: 0, data: [], pages: 0}, loading: true})
    ActivityService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_COMPLETE_ACTIVITIES, {data: res, loading: false})
    })
  }

  @Action
  async [Actions.LOAD_ALL_ACTIVITIES](filter: IFilter) {
    this.context.commit(Mutations.SET_ALL_ACTIVITIES,
      {
        loading: true,
        data: {
          total: 0,
          data: [],
          pages: 0
        }
      }
    )
    ActivityService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ALL_ACTIVITIES, {loading: false, data: res})
    })
  }

  @Action
  async [Actions.CASE_ACTIVITIES](caseId: string) {
    CaseService.getActivities(caseId).then(res => {
      this.context.commit(Mutations.SET_ACTIVITIES, res);
    })
  }

  //todo: actions with  policy activities  and invoice activities
  @Action
  async [Actions.ACTIVITY_SEARCH](filter: ISearchFilter) {
    await ActivityService.search<ITableResponse<IActivity>>(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY_SEARCH, res)
    })
  }

  @Action
  async [Actions.PHONE_CALL_SEARCH](filter: IFilter) {
    await ActivityService.findAll<ITableResponse<IActivity>>(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY_SEARCH, res)
    })
  }

  @Action
  async [Actions.LOAD_ACTIVITY](id: string) {
    await ActivityService.get<any>(id).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY, res)
    })
  }

  @Action
  async [Actions.LOAD_ACTIVITY_DETAIL](id: string) {
    this.context.commit(Mutations.SET_ENTITY_LOADING, true);
    await ActivityService.get<any>(id).then(res => {
      this.context.commit(Mutations.SET_ACTIVITY, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false);
    })
  }

  @Mutation
  [Mutations.EMIT_ACTIVITY_ACCESS](data) {
    this.viewAccess = data
  }

  @Mutation
  [Mutations.SET_ACTIVITIES](data: any) {
    this.activities = data.activities
    this.pendingActivities = data.pending
  }

  @Mutation
  [Mutations.SET_ACTIVITY_SEARCH](data: ITableResponse<IActivity>) {
    this.activityPage = data;
  }

  @Mutation
  [Mutations.SET_PENDING_ACTIVITIES](payload: any) {
    this.pendingPage.loading = payload.loading;
    this.pendingPage.data = payload.data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_ACTIVITIES](payload: any) {
    this.completePage.loading = payload.loading;
    this.completePage.data = payload.data
  }

  @Mutation
  [Mutations.SET_ALL_ACTIVITIES](payload: any) {
    this.allPage.loading = payload.loading
    this.allPage.data = payload.data;
  }

  @Mutation
  [Mutations.SET_ACTIVITY](data: IActivity) {
    this.activity = data
  }

  @Mutation
  [Mutations.SET_ACTIVITY_SEARCH_PAGE](data) {
    this.searchPage = data;
  }

  @Mutation
  [Mutations.SET_ACTIVITY_LATEST_REVIEWS](data) {
    this.latestReview = data;
  }
}
