import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ProviderLiveService from "@/core/services/ProviderLiveSevice";
import ProviderService from "@/core/services/ProviderService";
import type {ITableResponse} from "@/core/entity/ITableResponse";

@Module
export default class ProviderModule extends VuexModule {
  providerSearch: any;
  searching = false;
  providerPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  provider: any;

  activePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  completePage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  hospitalizations: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  @Action
  [Actions.PROVIDER_LIVE_SEARCH](terms: string) {
    this.context.commit('setProviderSearching', true)
    ProviderLiveService.search(terms).then(res => {
      this.context.commit(Mutations.SET_PROVIDER_LIVE_SEARCH, res)
    })
  }

  @Action
  [Actions.LOAD_HOSPITALIZATIONS](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ProviderService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_HOSPITALIZATIONS, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false);
    })
  }

  @Action
  [Actions.LOAD_PROVIDERS](filter: any) {
    ProviderService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PROVIDERS, res)
    })
  }

  @Action
  [Actions.LOAD_ACTIVE_PROVIDERS](filter: any) {
    ProviderService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTIVE_PROVIDERS, res)
    })
  }

  @Action
  [Actions.LOAD_COMPLETED_PROVIDERS](filter: any) {
    ProviderService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_COMPLETED_PROVIDERS, res)
    })
  }

  @Action
  [Actions.LOAD_PROVIDER](id) {
    ProviderService.get(id).then(res => {
      this.context.commit(Mutations.SET_PROVIDER, res)
    })
  }

  @Mutation
  [Mutations.SET_ACTIVE_PROVIDERS](data) {
    this.activePage = data
  }

  @Mutation
  [Mutations.SET_COMPLETED_PROVIDERS](data) {
    this.completePage = data
  }

  @Mutation
  ['setProviderSearching'](searching: boolean) {
    this.searching = searching;
  }

  @Mutation
  [Mutations.SET_PROVIDER_LIVE_SEARCH](data: any) {
    this.searching = false;
    this.providerSearch = data;
  }

  @Mutation
  [Mutations.SET_PROVIDERS](data) {
    this.providerPage = data
  }

  @Mutation
  [Mutations.SET_PROVIDER](data) {
    this.provider = data
  }

  @Mutation
  [Mutations.SET_HOSPITALIZATIONS](data) {
    this.hospitalizations = data
  }
}