import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ProductBenefitService from "@/core/services/ProductBenefitService";

@Module
export default class ProductBenefitModule extends VuexModule {
  page: any = {
    total: 0,
    data: [],
    totalPages: 0,
  }

  @Action
  [Actions.LOAD_PRODUCT_BENEFITS](filter) {
    ProductBenefitService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PRODUCT_BENEFITS, res)
    })
  }

  @Mutation
  [Mutations.SET_PRODUCT_BENEFITS](data) {
    this.page = data;
  }
}