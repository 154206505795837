import {BaseService} from "@/core/services/BaseService";

class GroupService extends BaseService<any> {
  endpoint = '/group'

  async addUser(id: any, model) {
    return await this.http().put(this.endpoint + "/" + id + "/users", model).then(res => {
      return res.data
    })
  }

  async remove(id, userId) {
    return await this.http().delete(this.endpoint + "/" + id + "/users/" + userId).then(res => {
      return res.data
    })
  }
}

export default new GroupService();