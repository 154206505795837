import {BaseService} from "@/core/services/BaseService";
import {generatorQueryString} from "@/core/helpers/functions";

class DashboardService extends BaseService<any> {
  endpoint = '/dashboard'

  async overview() {
    return this.http().get(this.endpoint + "/overview").then(res => {
      return res.data;
    })
  }

  async caseCreated(filter) {
    const queryString = generatorQueryString(filter);
    return this.http().get(this.endpoint + "/chart/created-cases?" + queryString).then(res => {
      return res.data
    })
  }

  async claimCreated(filter) {
    const queryString = generatorQueryString(filter);
    return this.http().get(this.endpoint + "/chart/created-claims?" + queryString).then(res => {
      return res.data
    })
  }

  async invoiceCreated(filter) {
    const queryString = generatorQueryString(filter);
    return this.http().get(this.endpoint + "/chart/created-invoices?" + queryString).then(res => {
      return res.data
    })
  }
  async paymentCreated(filter) {
    const queryString = generatorQueryString(filter);
    return this.http().get(this.endpoint + "/chart/created-payments?" + queryString).then(res => {
      return res.data
    })
  }

}

export default new DashboardService();