import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import FileShareService from "@/core/services/FileShareService";

@Module
export default class FileShareModule extends VuexModule {
  page: any = {
    data: [],
    total: 0,
    totalPages: 0
  }

  @Action
  [Actions.LOAD_FILE_SHARES](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    FileShareService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_FILE_SHARES, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }


  @Mutation
  [Mutations.SET_FILE_SHARES](data) {
    this.page = data
  }
}