import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import LogReportNotificationService from "@/core/services/LogReportNotificationService";

@Module
export default class LogReportNotificationModule extends VuexModule {
  page: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }


  @Action
  [Actions.LOAD_LOG_REPORT_NOTIFICATIONS](filter) {
    LogReportNotificationService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_LOG_REPORT_NOTIFICATIONS, {loading: false, data: res})
    })
  }


  @Mutation
  [Mutations.SET_LOG_REPORT_NOTIFICATIONS](payload) {
    this.page = payload;
  }


}