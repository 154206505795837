import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import PageService from "@/core/services/PageService";
import { activeMenu } from "@/core/helpers/functions";
import CookieService from "@/core/services/CookieService";

@Module
export default class PageModule extends VuexModule {
  menuData: any = []

  pageEntity: any = {
    isLoading: true,
    data: {}
  };

  sections: any = []
  selectedDoc = {};
  files: any = [];
  pages: any = {
    isLoading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  @Action
  [Actions.LOAD_SECTIONS](id) {
    PageService.sections(id).then(res => {
      this.context.commit(Mutations.SET_SECTIONS, res);
    })
  }
  @Action
  [Actions.LOAD_FILES](id) {
    PageService.files(id).then(res => {
      this.context.commit(Mutations.SET_FILES, res);
    })
  }

  @Action
  [Actions.LOAD_PAGE](id) {
    this.context.commit(Mutations.SET_PAGE_ENTITY, {isLoading: true, data: {}})
    PageService.get(id).then(res => {
      this.context.commit(Mutations.SET_PAGE_ENTITY, {isLoading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_PAGES](filter: any) {
    this.context.commit(Mutations.SET_PAGES, {
      isLoading: true,
      data: {
        total: 0,
        data: [],
        pages: 0,
      }
    })
    PageService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PAGES, {isLoading: true, data: res})
    })
  }


  @Mutation
  [Mutations.SET_ACTIVE_PAGE](item) {
    activeMenu(this.menuData, item);
    item.active = !item.active
  }

  @Mutation
  [Mutations.SET_HELP_MENU](data: any) {
    this.menuData = data;
  }

  @Mutation
  [Mutations.SET_PAGE_ITEMS](payload) {
    const menu: any = [];
    payload.data.forEach(item => {
      switch (item.type) {
        case 'FOLDER':
          menu.push({
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
            parentId: item.parentId,
          })
          break
        case'COLLECTION':
          menu.push({
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
          });
          break;
        case 'PAGE':
          menu.push({
            children: [],
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            parentId: item.parentId,
          });
          break;
      }
    })
    this.menuData.filter(item => {
      if (item.id === payload.item.id) {
        item.children = menu;
      } else if (item.type === 'COLLECTION' && item.children.find(x => x.id === payload.item.id)) {
        item.children.find(x => x.id === payload.item.id).children = menu;
      }
    })
  }

  @Mutation
  [Mutations.SET_PAGES](payload) {
    this.pages.isLoading = payload.isLoading;
    this.pages.data = payload.data
  }

  @Mutation
  [Mutations.SET_PAGE_ENTITY](payload) {
    this.pageEntity = payload;
  }

  @Mutation
  [Mutations.SET_FILES](payload) {
    this.files = payload;
  }
  @Mutation
  [Mutations.SET_SECTIONS](data) {
    this.sections = data;
    const selectedDoc = CookieService.get('SELECTED_DOC');
    if (selectedDoc) {
      const select = this.sections.find(item => item.id === selectedDoc);
      if (select) {
        this.selectedDoc = select;
      } else {
        this.selectedDoc = this.pageEntity.data
      }
    }else {
      this.selectedDoc = this.pageEntity.data
    }
  }
  @Mutation
  [Mutations.SET_SELECTED_DOC](payload) {
    if (payload.type === 'PAGE') {
      CookieService.remove('SELECTED_DOC')
    } else {
      CookieService.save('SELECTED_DOC', payload.id);
    }
    this.selectedDoc = payload;
  }
}