import { BaseService } from "@/core/services/BaseService";

class RoleService extends BaseService<any > {
  endpoint = 'role';

  public async list() {
    return await this.http().get(this.endpoint).then(res => {
      return res.data
    })
  }

}

export default new RoleService()