import { BaseService } from "@/core/services/BaseService";
import { generateString } from "@/core/helpers/functions";

class CheckListService extends BaseService<any> {
  endpoint = '/check-list'

  async list(data) {
    const queryString = generateString(data);
    return this.http().get(this.endpoint + "?" + queryString).then(res => {
      return res.data
    })
  }

  async updateStatus(id, status) {
    return this.http().put(this.endpoint + "/" + id + "/status?status=" + status).then(res => {
      return res.data;
    })
  }

  async updateStatusItem(id, status) {
    return this.http().put(this.endpoint + "/item/" + id + "/status?status=" + status).then(res => {
      return res.data;
    })
  }
}

export default new CheckListService();