import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ReserveService from "@/core/services/ReserveService";

@Module
export default class ReserveModule extends VuexModule {
  timelinePage: ITableResponse<any> = {total: 0, pages: 0, data: []};
  currentReserve: any
  access: any = {}

  @Action
  [Actions.LOAD_RESERVE_TIMELINES](filter: any) {
    ReserveService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_RESERVE_TIMELINES, res)
    })
  }

  @Mutation
  [Mutations.SET_RESERVE_TIMELINES](data) {
    this.timelinePage = data
  }

  @Mutation
  [Mutations.EMIT_RESERVE_ACCESS](data) {
    this.access = data
  }

  @Mutation
  [Mutations.SET_RESERVE](data) {
    this.currentReserve = data
  }
}