import {BaseService} from "@/core/services/BaseService";

class QuicksightService extends BaseService<any> {
  endpoint = "/quicksight"

  public async getCode<T>(code: string): Promise<any> {
    return await this.http().get<T>(this.endpoint + "/code/" + code).then(res => {
      return res.data
    })
  }

  public async menu() {
    return await this.http().get(this.endpoint + "/menu").then(res => {
      return res.data
    })
  }

  public async visual(dashboardId, sheetId, visualId) {
    return await this
      .http()
      .get(this.endpoint + "/visual/url?dashboardId=" + dashboardId + "&sheetId=" + sheetId + "&visualId=" + visualId)
      .then(res => {
        return res.data
      })
  }

  public async dashboard(dashboardId) {
    return await this
      .http()
      .get(this.endpoint + "/dashboard/url?dashboardId=" + dashboardId)
      .then(res => {
        return res.data
      })
  }
}

export default new QuicksightService()
