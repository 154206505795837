import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class ValidationModule extends VuexModule {
  errors: any = {};

  @Action
  [Actions.CREATE_FORM_ERROR](errors: any) {
    this.context.commit(Mutations.SET_FORM_ERROR, errors)
  }

  @Mutation
  [Mutations.SET_FORM_ERROR](data: any) {
    const finalError = {};
    data.forEach(item => {
      finalError[item.field] = [item.message]
    })
    this.errors = finalError;

  }

}