import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import GroupService from "@/core/services/GroupService";

@Module
export default class GroupModule extends VuexModule {

  page: any = {
    total: 0,
    data: [],
    totalPages: 0,
  }

  entity: any = {}

  @Action
  [Actions.LOAD_GROUPS](filter) {
    GroupService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_GROUPS, res)
    })
  }

  @Action
  [Actions.LOAD_GROUP](id) {
    GroupService.get(id).then(res => {
      this.context.commit(Mutations.SET_GROUP, res)
    })
  }

  @Mutation
  [Mutations.SET_GROUPS](data) {
    this.page = data;
  }

  @Mutation
  [Mutations.SET_GROUP](data) {
    this.entity = data
  }
}