import {BaseService} from "@/core/services/BaseService";

class CustomerService extends BaseService<any> {
  endpoint = "/customer"

  async confirm(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/confirm", data).then(res => {
      return res.data;
    })
  }
}

export default new CustomerService();