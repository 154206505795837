import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ActionTemplateService from "@/core/services/ActionTemplateService";


@Module
export default class ActionTemplateModule extends VuexModule {
  page: any = {
    data: [],
    total: 0,
    totalPages: 0
  }

  @Action
  [Actions.LOAD_ACTION_TEMPLATES](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ActionTemplateService.listAll(filter).then(res => {
      this.context.commit(Mutations.SET_ACTION_TEMPLATES, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }


  @Mutation
  [Mutations.SET_ACTION_TEMPLATES](data) {
    this.page = data
  }
}