import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";
import {buildFormData} from "@/core/helpers/functions";

class CaseService extends BaseService<any> {
  endpoint = ENDPOINT.CASE;

  /**
   * get activities specified case
   * @param caseId
   */
  async getActivities(caseId: string) {
    return this.http().get(this.endpoint + "/" + caseId + "/activities").then(res => {
      return res.data
    })
  }

  async updateStatus(caseId, status) {
    return this.http().put(this.endpoint + "/" + caseId + "/status", status).then(res => {
      return res.data
    })
  }

  async updateTripX(caseId, tripDetail) {
    return this.http().put(this.endpoint + "/" + caseId + "/tripx", tripDetail).then(res => {
      return res.data
    })
  }

  async moveCase(caseId, moveDetail) {
    return this.http().put(this.endpoint + "/" + caseId + "/move", moveDetail).then(res => {
      return res.data
    })
  }

  async cmUpdate(caseId, data) {
    return this.http().put(this.endpoint + "/" + caseId + "/cm-status", data).then(res => {
      return res.data
    })
  }

  async hasCase(id) {
    return this.http().get(this.endpoint + "/" + id + "/has-other").then(res => {
      return res.data
    })
  }

  async emailFormat(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/email/format", data).then(res => {
      return res.data
    })
  }

  async sendAck(id, data, files) {
    const formData = buildFormData(data);
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return this.uploadHttp().post(this.endpoint + "/" + id + "/email/ack", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data
    })
  }

  async activeCM(id) {
    return await this.http().put(this.endpoint + "/" + id + "/cm/active").then(res => {
      return res.data

    })
  }
}

export default new CaseService();