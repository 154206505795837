import axios, {AxiosInstance} from "axios";

class ProviderLiveService {
  static _axios: AxiosInstance = axios.create({
    baseURL: 'https://clinicaltables.nlm.nih.gov/api'
  })

  protected http(): AxiosInstance {
    return ProviderLiveService._axios;
  }

  async search(terms: string) {
    return this.http().get('npi_org/v3/search?authenticity_token=&terms=' +
      terms +
      "&ef=addr_practice.line1,addr_practice.line2,addr_practice.city,addr_practice.state,addr_practice.zip,addr_practice.country")
      .then(res => {
        return res.data;
      })
  }

  async fullSearch(terms: string) {
    return this.http().get('npi_org/v3/search?authenticity_token=&terms=' +
      terms +
      "&ef=addr_practice.line1,addr_practice.line2,addr_practice.city,addr_practice.state,addr_practice.zip,addr_practice.country")
      .then(res => {
        return res.data;
      })
  }
}

export default new ProviderLiveService();