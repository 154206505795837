import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import CompanyService from "@/core/services/CompanyService";
import { ICompany } from "@/core/entity/ICompany";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import type { IFilter } from "@/core/entity/IFilter";

@Module
export default class CompanyModule extends VuexModule {
  companySelect: any[] = [];
  clientSelect: any[] = [];
  companyPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  company: any = {};

  @Action
  async [Actions.LOAD_COMPANIES](filter: IFilter) {
    return await CompanyService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_COMPANIES, res)
    })
  }

  @Action
  async [Actions.LOAD_COMPANY](id) {
    return await CompanyService.get(id).then(res => {
      this.context.commit(Mutations.SET_COMPANY, res)
    })
  }

  @Action
  async [Actions.LOAD_CLIENTS]() {
    return await CompanyService.clients().then(res => {
      this.context.commit(Mutations.SET_CLIENTS, res)
    })
  }

  @Action
  async [Actions.LOAD_ALL_CLIENT]() {
    return await CompanyService.all().then(res => {
      this.context.commit(Mutations.SET_CLIENTS, res)
    })
  }

  @Mutation
  [Mutations.SET_COMPANIES](data: ITableResponse<any>) {
    this.companyPage = data;
  }


  @Mutation
  [Mutations.SET_COMPANY](data: any) {
    this.company = data
  }


  @Mutation
  [Mutations.SET_CLIENTS](data: ICompany[]) {
    this.clientSelect = data
  }
}