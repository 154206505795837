import { computed } from "vue";

export default {
  mounted(el, binding) {
    const vm = binding.instance
    const currentUser = computed<any>(() => vm.$store?.getters.currentUser)
    if (currentUser.value === undefined || currentUser.value === null) {
      el.classList.add("d-none")
    }
    vm.$store.watch(() => vm.$store.state.AuthModule.user, (user: any) => {

      if (user && user.roles && !user.roles.find(item => item.name === 'ROLE_ADMIN')) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      } else {
        el.classList.remove("d-none")
      }
    })
    if (currentUser.value.roles) {
      const hasRoleAdmin = currentUser.value.roles.find(item => item.name === 'ROLE_ADMIN')
      if (!hasRoleAdmin) {
        el.parentNode.removeChild(el);
      }
    }
  }
}