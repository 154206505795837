import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/customers/pending-claims",
        name: "customerPendingClaims",
        component: () => import("@/views/customer/PendingClaims.vue"),
      },
      {
        path: "/customers/inbox",
        name: "inboxCustomer",
        component: () => import("@/views/customer/inbox/CustomerInbox.vue"),
      },
      {
        path: "/customers/new",
        name: "newCustomer",
        component: () => import("@/views/customer/NewCustomers.vue"),
      },
      {
        path: "/customers/confirmed",
        name: "confirmCustomer",
        component: () => import("@/views/customer/ConfirmCustomers.vue"),
      },
      {
        path: "/customer-info/:id",
        name: "customerDetail",
        redirect: to => ({
          name: "customerOverview",
          params: {id: to.params.id},
        }),
        component: () => import("@/views/customer/Customer.vue"),
        children: [
          {
            path: "/customer-info/:id/overview",
            name: "customerOverview",
            component: () => import("@/views/customer/CustomerOverview.vue"),
          },
          {
            path: "/customer-info/:id/claims",
            name: "customerClaims",
            component: () => import("@/views/customer/Claims.vue"),
            props: route => ({customerId: route.params.id})
          },
          {
            path: "/customer-info/:id/inbox",
            name: "customerInbox",
            component: () => import("@/views/customer/inbox/CustomerChatBox.vue"),
            props: route => ({customerId: route.params.id})
          },
        ]
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/documents/:id",
        name: "documentDetail",
        component: () => import("@/views/document/Document.vue"),
      },
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/views/chat/Chat.vue"),
        children: [
          {
            path: "/chat/:id",
            name: "chatBox",
            component: () => import("@/views/chat/ChatBox.vue"),
            props: route => ({toId: route.params.id})
          },
        ]
      },
      {
        path: "/policies",
        name: "policies",
        component: () => import("@/views/policy/Policies.vue"),
      },
      {
        path: "/integration-policies",
        name: "integrationPolicies",
        component: () => import("@/views/policy/IntegrationSearch.vue"),
      },
      {
        path: "/list-policy",
        name: "listPolicy",
        component: () => import("@/views/policy/List.vue"),
        children: [
          {
            path: "/list-policy/recent",
            name: "recentPolicies",
            component: () => import("@/views/policy/Recent.vue"),
          },
          {
            path: "/list-policy/watching",
            name: "watchingPolicies",
            component: () => import("@/views/policy/Watching.vue"),
          },
        ]
      },
      {
        path: "/new-cases",
        name: "newCases",
        component: () => import("@/views/case/SearchNewCases.vue"),
      },
      {
        path: "/new-claims",
        name: "newClaims",
        component: () => import("@/views/claim/SearchNewClaims.vue"),
      },
      {
        path: "/new-invoices",
        name: "newInvoices",
        component: () => import("@/views/invoice/SearchNewInvoice.vue"),
      },
      {
        path: "/claims-fast-track",
        name: "fastTrack",
        component: () => import("@/views/claim/FastTrack.vue"),
      },
      {
        path: "/list-case",
        name: "listCase",
        component: () => import("@/views/case/List.vue"),
        children: [
          {
            path: "/list-case/recent",
            name: "recentCases",
            component: () => import("@/views/case/Recent.vue"),
          },
          {
            path: "/list-case/watching",
            name: "watchingCases",
            component: () => import("@/views/case/Watching.vue"),
          },
          {
            path: "/list-case/my-cases",
            name: "myCases",
            component: () => import("@/views/case/MyCases.vue"),
          },
          {
            path: "/list-case/deleted-cases",
            name: "deletedCases",
            component: () => import("@/views/case/DeletedCases.vue"),
          },
        ]
      },
      {
        path: "/list-claim",
        name: "listClaim",
        component: () => import("@/views/claim/List.vue"),
        children: [
          {
            path: "/list-claim/recent",
            name: "recentClaims",
            component: () => import("@/views/claim/Recent.vue"),
          },
          {
            path: "/list-claim/watching",
            name: "watchingClaims",
            component: () => import("@/views/claim/Watching.vue"),
          },
          {
            path: "/list-claim/my-claims",
            name: "myClaims",
            component: () => import("@/views/claim/MyClaims.vue"),
          },
          {
            path: "/list-claim/deleted-claims",
            name: "deletedClaims",
            component: () => import("@/views/claim/DeletedClaim.vue"),
          },
        ]
      },
      {
        path: "/list-invoice",
        name: "listInvoice",
        component: () => import("@/views/invoice/List.vue"),
        children: [
          {
            path: "/list-invoice/recent",
            name: "recentInvoices",
            component: () => import("@/views/invoice/Recent.vue"),
          },
          {
            path: "/list-invoice/watching",
            name: "watchingInvoices",
            component: () => import("@/views/invoice/Watching.vue"),
          },
          {
            path: "/list-invoice/my-invoices",
            name: "my-invoices",
            component: () => import("@/views/invoice/MyInvoices.vue"),
          },
          {
            path: "/list-invoice/deleted",
            name: "deletedInvoices",
            component: () => import("@/views/invoice/DeletedInvoices.vue"),
          },
        ]
      },
      {
        path: "/list-payment",
        name: "ListPayment",
        component: () => import("@/views/payment/List.vue"),
        children: [
          {
            path: "/list-payment/recent",
            name: "recentPayment",
            component: () => import("@/views/payment/Recent.vue"),
          },
        ]
      },
      {
        path: "/policy/create",
        name: "policyCreate",
        component: () => import("@/views/policy/Create.vue"),
      },
      {
        path: "/templates",
        name: "listTemplate",
        component: () => import("@/views/template/List.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/company/Companies.vue"),
      },
      {
        path: "/list-company",
        name: 'listCompany',
        component: () => import("@/views/company/List.vue"),
        children: [
          {
            path: "/list-company/clients",
            name: "clients",
            component: () => import("@/views/company/Clients.vue"),
          },
          {
            path: "/list-company/ppos",
            name: "ppos",
            component: () => import("@/views/company/PPOs.vue"),
          },
        ]
      },
      {
        path: "/companies/:id",
        name: "companyDetail",
        component: () => import("@/views/company/Company.vue"),
        children: [
          {
            path: "/companies/:id/overview",
            name: "companyOverview",
            component: () => import("@/views/company/Overview.vue"),
          },
          {
            path: "/companies/:id/products",
            name: "companyProducts",
            component: () => import("@/views/company/Products.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/companies/:id/services",
            name: "companyServices",
            component: () => import("@/views/service/List.vue"),
            props: route => ({companyId: route.params.id})
          },
          {
            path: "/companies/:id/templates",
            name: "companyTemplates",
            component: () => import("@/views/company/Templates.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/companies/:id/notifications",
            name: "companyNotification",
            component: () => import("@/views/notification/List.vue"),
            props: route => ({clientId: route.params.id})
          },
        ]
      },
      {
        path: "/report/:code",
        name: "quicksightReportShow",
        component: () => import("@/views/quicksight/Show.vue"),
        props: route => ({code: route.params.code})
      },
      {
        path: "/products",
        name: "productDetail",
        component: () => import("@/views/product/Products.vue"),
      },
      {
        path: "/products/:id",
        name: "product",
        component: () => import("@/views/product/Product.vue"),
        children: [
          {
            path: "/products/:id/documents",
            name: "productDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'PRODUCT'})
          },
          {
            path: "/products/:id/benefits",
            name: "productBenefits",
            component: () => import("@/views/product/ProductBenefits.vue"),
            props: route => ({productId: route.params.id})
          },
        ]
      },
      {
        path: "/policy/update/:id",
        name: "policyUpdate",
        component: () => import("@/views/policy/Update.vue"),
      },
      {
        path: "/policies/:id/create-case",
        name: "policyCreateCase",
        component: () => import("@/views/policy/CreateCase.vue"),
      },
      {
        path: "/policies/:id/create-case/:insuredId",
        name: "policyCreateCaseSpecifiedInsured",
        component: () => import("@/views/policy/CreateCase.vue"),
        props: route => ({insuredId: route.params.insuredId})
      },
      {
        path: "/policies/:id/create-claim",
        name: "policyCreateClaim",
        component: () => import("@/views/policy/CreateClaim.vue"),
      },
      {
        path: "/policies/:id/create-claim/:insuredId",
        name: "policyCreateClaimSpecifiedInsured",
        component: () => import("@/views/policy/CreateClaim.vue"),
        props: route => ({insuredId: route.params.insuredId})
      },
      {
        path: "/policies/:id",
        name: "policy",
        component: () => import("@/views/policy/Policy.vue"),
        children: [
          {
            path: "/policies/:id",
            name: "policyOverview",
            component: () => import("@/views/policy/Overview.vue"),
          },
          {
            path: "/policies/:id/cases",
            name: "policyCases",
            component: () => import("@/views/policy/Cases.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/policies/:id/claims",
            name: "policyClaims",
            component: () => import("@/views/policy/Claims.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/policies/:id/activities",
            name: "policyActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'POLICY'})
          },
          {
            path: "/policies/:id/documents",
            name: "policyDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'POLICY'})
          },
          {
            path: "/policies/:id/audits",
            name: "policyAudits",
            component: () => import("@/views/audit/Audits.vue"),
            props: route => ({objectId: route.params.id, objectType: 'POLICY'})
          },
          {
            path: "/policies/:id/emails",
            name: "policyEmails",
            component: () => import("@/views/email/List.vue"),
            props: route => ({objectId: route.params.id, objectType: 'POLICY'})
          }
        ]
      },
      {
        path: "/phone-call/:id",
        name: "phoneCallPage",
        component: () => import("@/views/activity/PhoneCallPage.vue"),
      },
      {
        path: "/claims/:id",
        name: "claim",
        component: () => import("@/views/claim/Claim.vue"),
        children: [
          {
            path: "/claims/:id",
            name: "claimOverview",
            component: () => import("@/views/claim/Overview.vue"),
          },
          {
            path: "/claims/:id/invoices",
            name: "claimInvoices",
            component: () => import("@/views/claim/Invoices.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/claims/:id/documents",
            name: "claimDocuments",
            component: () => import("@/views/claim/document/Documents.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/claims/:id/activities",
            name: "claimsActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'CLAIM'})
          },
          {
            path: "/claims/:id/audits",
            name: "claimAudits",
            component: () => import("@/views/audit/Audits.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CLAIM'})
          },
          {
            path: "/claims/:id/reserve",
            name: "claimReserve",
            component: () => import("@/views/reserve/Reserve.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CLAIM'})
          },
          {
            path: "/claims/:id/analytics",
            name: "claimAnalytics",
            component: () => import("@/views/scan/ScanEntity.vue"),
            props: route => ({object: {objectId: route.params.id, objectType: 'CLAIM'}})
          },
          {
            path: "/claims/:id/tasks",
            name: "taskClaims",
            component: () => import("@/views/task/Tasks.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CLAIM'})
          },
          {
            path: "/claims/:id/reminders",
            name: "taskReminders",
            component: () => import("@/views/reminder/Reminders.vue"),
            props: route => ({object: {objectId: route.params.id, objectType: 'CLAIM'}})
          },
          {
            path: "/claims/:id/adjudication",
            name: "claimAdjudication",
            component: () => import("@/views/claim/adjudication/Adjudication.vue"),
            children: [
              {
                path: "/claims/:id/adjudication/summary",
                name: "claimAdjudicationSummary",
                component: () => import("@/views/claim/adjudication/Summary.vue"),
              },
              {
                path: "/claims/:id/adjudication/documents",
                name: "claimAdjudicationDocuments",
                component: () => import("@/views/document/Documents.vue"),
                props: route => ({id: route.params.id, type: 'CLAIM', area: 'CA'})
              },
              {
                path: "/claims/:id/adjudication/activities",
                name: "claimAdjudicationActivities",
                component: () => import("@/views/activity/Activities.vue"),
                props: route => ({id: route.params.id, type: 'CLAIM', area: 'CA'})
              },
            ]
          },
          {
            path: "/claims/:id/recovery",
            name: "claimRecovery",
            component: () => import("@/views/claim/recovery/Recovery.vue"),
            children: [
              {
                path: "/claims/:id/recovery/summary",
                name: "claimRecoverySummary",
                component: () => import("@/views/claim/recovery/RecoverySummary.vue"),
              },
              {
                path: "/claims/:id/recovery/documents",
                name: "claimRecoveryDocuments",
                component: () => import("@/views/document/Documents.vue"),
                props: route => ({id: route.params.id, type: 'CLAIM', area: 'CR'})
              },
              {
                path: "/claims/:id/recovery/activities",
                name: "claimRecoveryActivities",
                component: () => import("@/views/activity/Activities.vue"),
                props: route => ({id: route.params.id, type: 'CLAIM', area: 'CR'})
              },
            ]
          },
          {
            path: "/claims/:id/processes",
            name: "claimProcess",
            component: () => import("@/views/process/ProcessList.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CLAIM'})
          },
          {
            path: "/claims/:id/customer",
            name: "claimCustomer",
            component: () => import("@/views/claim/Customer.vue"),
          },
          {
            path: "/claims/:id/emails",
            name: "claimEmail",
            component: () => import("@/views/email/List.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CLAIM'})
          },
        ]
      },
      {
        path: "/claim/update/:id",
        name: "claimUpdate",
        component: () => import("@/views/claim/Update.vue"),
      },
      {
        path: "/claim/:id/create-invoice/:type",
        name: "claimCreateInvoice",
        component: () => import("@/views/claim/CreateInvoice.vue"),
        props: route => ({id: route.params.id, type: route.params.type})
      },
      {
        path: "/claims",
        name: "claims",
        component: () => import("@/views/claim/Claims.vue"),
      },
      {
        path: "/cases",
        name: "cases",
        component: () => import("@/views/case/Cases.vue"),
      },
      {
        path: "/case/create",
        name: "caseCreate",
        component: () => import("@/views/case/Create.vue"),
      },
      {
        path: "/case/pending-activities",
        name: "casePendingActivities",
        component: () => import("@/views/activity/Search.vue"),
        props: () => ({entity: 'CASE'})
      },
      {
        path: "/claim/pending-activities",
        name: "claimPendingActivities",
        component: () => import("@/views/activity/Search.vue"),
        props: () => ({entity: 'CLAIM'})
      },
      {
        path: "/case/update/:id",
        name: "caseUpdate",
        component: () => import("@/views/case/Update.vue"),
      },
      {
        path: "/provider/update/:id",
        name: "providerUpdate",
        component: () => import("@/views/provider/UpdateProvider.vue"),
      },
      {
        path: "/case/:id/add-provider",
        name: "caseAddProvider",
        component: () => import("@/views/case/AddProvider.vue"),
      },
      {
        path: "/cases/:id",
        name: "case",
        component: () => import("@/views/case/Case.vue"),
        children: [
          {
            path: "/cases/:id",
            name: "caseOverview",
            component: () => import("@/views/case/Overview.vue"),
          },
          {
            path: "/cases/:id/providers",
            name: "caseProviders",
            component: () => import("@/views/case/Providers.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/cases/:id/activities",
            name: "caseActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'CASE'})
          },
          {
            path: "/cases/:id/documents",
            name: "caseDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'CASE'})
          },
          {
            path: "/cases/:id/audits",
            name: "caseAudit",
            component: () => import("@/views/audit/Audits.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CASE'})
          },
          {
            path: "/cases/:id/reserve",
            name: "caseReserve",
            component: () => import("@/views/reserve/Reserve.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CASE'})
          },
          {
            path: "/cases/:id/case-management",
            name: "caseManagement",
            component: () => import("@/views/case/CaseManagement.vue"),
            children: [
              {
                path: "/cases/:id/case-management/summary",
                name: "caseManagementSummary",
                component: () => import("@/views/case/ManagementOverview.vue"),
              },
              {
                path: "/cases/:id/case-management/activities",
                name: "caseManagementActivities",
                component: () => import("@/views/activity/Activities.vue"),
                props: route => ({id: route.params.id, type: 'CASE', area: 'CM'})
              },
              {
                path: "/cases/:id/case-management/documents",
                name: "caseManagementDocs",
                component: () => import("@/views/document/Documents.vue"),
                props: route => ({id: route.params.id, type: 'CASE', area: 'CM'})
              },
              {
                path: "/cases/:id/case-management/reviews",
                name: "caseManagementReviews",
                component: () => import("@/views/activity/Reviews.vue"),
                props: route => ({cazeId: route.params.id})
              },
            ]
          },
          {
            path: "/cases/:id/claims",
            name: "claimsCase",
            component: () => import("@/views/case/Claims.vue"),
            props: route => ({cazeId: route.params.id})
          },
          {
            path: "/cases/:id/analytics",
            name: "caseAnalytics",
            component: () => import("@/views/scan/ScanEntity.vue"),
            props: route => ({object: {objectId: route.params.id, objectType: 'CASE'}})
          },
          {
            path: "/cases/:id/tasks",
            name: "caseTasks",
            component: () => import("@/views/task/Tasks.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CASE'})
          },
          {
            path: "/cases/:id/reminders",
            name: "caseReminders",
            component: () => import("@/views/reminder/Reminders.vue"),
            props: route => ({object: {objectId: route.params.id, objectType: 'CASE'}})
          },
          {
            path: "/cases/:id/fees",
            name: "caseFees",
            component: () => import("@/views/service/payment/List.vue"),
            props: route => ({cazeId: route.params.id})
          },
          {
            path: "/cases/:id/processes",
            name: "processes",
            component: () => import("@/views/process/ProcessList.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CASE'})
          },
          {
            path: "/cases/:id/emails",
            name: "caseEmails",
            component: () => import("@/views/email/List.vue"),
            props: route => ({objectId: route.params.id, objectType: 'CASE'})
          }
        ]
      },
      {
        path: "/client-fees",
        name: "clientFees",
        component: () => import("@/views/service/payment/ServicePaymentSearch.vue"),
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/invoice/Invoices.vue"),
      },
      {
        path: "/processes/:id",
        name: "processDetail",
        redirect: to => ({
          name: "processOverview",
          params: {id: to.params.id},
        }),
        component: () => import("@/views/process/Process.vue"),
        children: [
          {
            path: "/processes/:id/overview",
            name: "processOverview",
            component: () => import("@/views/process/Overview.vue"),
          },
          {
            path: "/processes/:id/tasks",
            name: "processTasks",
            component: () => import("@/views/task/Tasks.vue"),
            props: route => ({objectId: route.params.id, objectType: 'PROCESS'})
          },
          {
            path: "/processes/:id/activities",
            name: "processActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'PROCESS'})
          },
          {
            path: "/processes/:id/documents",
            name: "processDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'PROCESS'})
          },
        ]
      },
      {
        path: "/invoices/:id",
        name: "invoice",
        component: () => import("@/views/invoice/Invoice.vue"),
        children: [

          {
            path: "/invoices/:id",
            name: "invoiceOverview",
            component: () => import("@/views/invoice/Overview.vue"),
          },
          {
            path: "/invoices/:id/activities",
            name: "invoiceActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'INVOICE'})
          },
          {
            path: "/invoices/:id/documents",
            name: "invoiceDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'INVOICE'})
          },
          {
            path: "/invoices/:id/audits",
            name: "invoiceAudits ",
            component: () => import("@/views/audit/Audits.vue"),
            props: route => ({objectId: route.params.id, objectType: 'INVOICE'})
          },
          {
            path: "/invoices/:id/payments",
            name: "invoicePayments",
            component: () => import("@/views/payment/Payments.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/invoices/:id/tasks",
            name: "invoiceTasks",
            component: () => import("@/views/task/Tasks.vue"),
            props: route => ({objectId: route.params.id, objectType: 'INVOICE'})
          },
          {
            path: "/invoices/:id/processes",
            name: "invoiceProcess",
            component: () => import("@/views/process/ProcessList.vue"),
            props: route => ({objectId: route.params.id, objectType: 'INVOICE'})
          }
        ]
      },
      {
        path: "/invoice/update/:id",
        name: "invoiceUpdate",
        component: () => import("@/views/invoice/Update.vue"),
      },
      {
        path: "/statement/convert/:id/:convertType",
        name: "statementConvert",
        component: () => import("@/views/invoice/ConvertStatement.vue"),
        props: route => ({convertType: route.params.convertType})
      },
      {
        path: "/invoice/:id/repricing",
        name: "repricingInvoice",
        component: () => import("@/views/invoice/InvoiceRepricing.vue"),
      },
      {
        path: "/invoice/:id/adjudicate",
        name: "adjudicateInvoice",
        component: () => import("@/views/invoice/InvoiceAdjudicate.vue"),
      },
      {
        path: "/invoice/:id/add-payment",
        name: "addPayment",
        component: () => import("@/views/payment/Create.vue"),
      },
      {
        path: "/invoice/:id/edit-payment/:paymentId",
        name: "editPayment",
        component: () => import("@/views/payment/Update.vue"),
        props: route => ({id: route.params.id, paymentId: route.params.paymentId})
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/user/Profile.vue"),
        children: [
          {
            path: "/profile/overview",
            name: "/overview",
            component: () => import("@/views/user/profile/Overview.vue"),
          },
          {
            path: "/profile/settings",
            name: "/settings",
            component: () => import("@/views/user/profile/Settings.vue"),
          },
          {
            path: "/profile/theme",
            name: "/theme",
            component: () => import("@/views/user/profile/Background.vue"),
          },
          {
            path: "/profile/change-password",
            name: "changePassword",
            component: () => import("@/views/user/profile/UpdatePassword.vue"),
          },
          {
            path: "/profile/analytics",
            name: "profileAnalytics",
            component: () => import("@/views/user/profile/Analytics.vue"),
          }
        ]
      },
      {
        path: "/call-center/dashboard",
        name: "callCenterDashboard",
        component: () => import("@/views/assistance/call-center/Dashboard.vue"),
      },
      {
        path: "/call-center/search",
        name: "callCenterSearch",
        component: () => import("@/views/assistance/call-center/Search.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/user/Users.vue"),
      },
      {
        path: "/users/:id",
        name: "user",
        component: () => import("@/views/user/User.vue"),
        children: [
          {
            path: "/users/:id/logs",
            name: "loginHistory",
            component: () => import("@/views/user/LoginHistory.vue"),
          },
          {
            path: "/users/:id/roles",
            name: "userRoles",
            component: () => import("@/views/user/Roles.vue"),
            props: route => ({id: route.params.id})
          },
          {
            path: "/users/:id/permissions",
            name: "userPermissions",
            component: () => import("@/views/user/Permissions.vue"),
            props: route => ({userId: route.params.id})
          },
          {
            path: "/users/:id/action-log",
            name: "userActionLog",
            component: () => import("@/views/actionView/UserActionLog.vue"),
            props: route => ({userId: route.params.id})
          },
          {
            path: "/users/:id/analytics",
            name: "userAnalytic",
            component: () => import("@/views/quicksight/Analytics.vue"),
            props: route => ({objectId: route.params.id, objectType: 'USER'})
          },
        ]
      },
      {
        path: "/content-mapping",
        name: "contentMapping",
        component: () => import("@/views/contentmapping/List.vue"),
      },
      {
        path: "/quicksight-report",
        name: "quicksightReport",
        component: () => import("@/views/quicksight/List.vue"),
      },
      {
        path: "/reports/:code",
        name: "customReport",
        component: () => import("@/views/report/Report.vue"),
        props: route => ({code: route.params.code})
      },
      {
        path: "/login-audit",
        name: "loginAudits",
        component: () => import("@/views/user/LoginAudits.vue"),
      },
      {
        path: "/help/doc/collection/:id",
        name: 'pageCollection',
        component: () => import("@/views/page/Collection.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/help/doc/folder/:id",
        name: 'pageFolder',
        component: () => import("@/views/page/Collection.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/help/doc/:id",
        name: 'pageDetail',
        component: () => import("@/views/page/Page.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/payments",
        name: 'PaymentSearch',
        component: () => import("@/views/payment/Search.vue"),
      },
      {
        path: "/group-payments",
        name: 'PaymentGroup',
        component: () => import("@/views/payment/Groups.vue"),
      },
      {
        path: "/activities",
        name: 'activitySearch',
        component: () => import("@/views/activity/Search.vue"),
      },
      {
        path: "/action-templates",
        name: 'actionTemplates',
        component: () => import("@/views/actionTemplate/ActionTemplates.vue"),
      },
      {
        path: "/action-template-groups",
        name: 'actionTemplateGroups',
        component: () => import("@/views/actionTemplateGroup/ActionTemplateGroups.vue"),
      },
      {
        path: "/notifications",
        name: 'notifications',
        component: () => import("@/views/notification/List.vue"),
      },
      {
        path: "/lookups",
        name: 'lookups',
        component: () => import("@/views/lookup/List.vue"),
      },
      {
        path: "/tags",
        name: 'tags',
        component: () => import("@/views/tag/List.vue"),
      },
      {
        path: "/visual",
        name: 'visual',
        component: () => import("@/views/quicksight/Visual.vue"),
      },
      {
        path: "/log-reports",
        name: 'logReports',
        component: () => import("@/views/logReport/LogReportSearch.vue"),
      },
      {
        path: "/log-reports/:id",
        name: 'logReport',
        component: () => import("@/views/logReport/Report.vue"),
        children: [
          {
            path: "/log-reports/:id/tasks",
            name: "logReportTasks",
            component: () => import("@/views/task/Tasks.vue"),
            props: route => ({objectId: route.params.id, objectType: 'LOG_REPORT'})
          },
          {
            path: "/log-reports/:id/activities",
            name: "logReportActivities",
            component: () => import("@/views/activity/Activities.vue"),
            props: route => ({id: route.params.id, type: 'LOG_REPORT'})
          },
          {
            path: "/log-reports/:id/documents",
            name: "logReportDocuments",
            component: () => import("@/views/document/Documents.vue"),
            props: route => ({id: route.params.id, type: 'LOG_REPORT'})
          },
          {
            path: "/log-reports/:id/notifications",
            name: "reportNotification",
            component: () => import("@/views/logReport/notification/LogReportNotifications.vue"),
            props: route => ({reportId: route.params.id})
          },
        ]

      },
      {
        path: "/providers",
        name: "providerSearch",
        component: () => import("@/views/provider/Search.vue"),
      },
      {
        path: "/case-management/cases",
        name: "caseManagementCases",
        component: () => import("@/views/case/management/Inpatients.vue"),
      },
      {
        path: "/case-management/outpatient-cases",
        name: "caseManagementOutpatientsCases",
        component: () => import("@/views/case/management/Outpatients.vue"),
      },
      {
        path: "/case-management/hospitalizations",
        name: "hospitalizations",
        component: () => import("@/views/case/management/Hospitalizations.vue"),
      },
      {
        path: "/case-management/coc",
        name: "actionCOC",
        component: () => import("@/views/case/management/Actions.vue"),
      },
      {
        path: "/team/dashboard",
        name: "teamDashboard",
        component: () => import("@/views/actionView/Dashboard.vue"),
      },
      {
        path: "/team/action-log",
        name: "teamActionLog",
        component: () => import("@/views/actionView/ActionLog.vue"),
      },
      {
        path: "/team/scans",
        name: "teamScans",
        component: () => import("@/views/scan/List.vue"),
      },
      {
        path: "/teams",
        name: "teams",
        component: () => import("@/views/team/List.vue"),
      },
      {
        path: "/teams/:id",
        name: "TeamDetail",
        component: () => import("@/views/team/Team.vue"),
        children: [
          {
            path: "/teams/:id/members",
            name: "teamMembers",
            component: () => import("@/views/team/Members.vue"),
            props: route => ({groupId: route.params.id})
          },
          {
            path: "/teams/:id/permissions",
            name: "teamPermissions",
            component: () => import("@/views/team/Permissions.vue"),
            props: route => ({teamId: route.params.id})
          },
          {
            path: "/teams/:id/logins",
            name: "teamLogins",
            component: () => import("@/views/team/Logins.vue"),
            props: route => ({groupId: route.params.id})
          },
          {
            path: "/teams/:id/action-logs",
            name: "teamActionLogs",
            component: () => import("@/views/team/ActionLogs.vue"),
            props: route => ({groupId: route.params.id})
          },
          {
            path: "/teams/:id/analytics",
            name: "teamsAnalytic",
            component: () => import("@/views/team/Analytics.vue"),
            props: route => ({objectId: route.params.id, objectType: 'TEAM'})
          },
        ]
      },
      {
        path: "/reminder-search",
        name: "searchReminder",
        component: () => import("@/views/reminder/Search.vue"),
      },
      {
        path: "/my-reminders",
        name: "myReminders",
        component: () => import("@/views/reminder/MyReminders.vue"),
      },
      {
        path: "/team-reminders",
        name: "teamReminders",
        component: () => import("@/views/reminder/MyTeamReminders.vue"),
      },
      {
        path: "/my-tasks",
        name: "myTasks",
        component: () => import("@/views/task/MyTasks.vue"),
      },
      {
        path: "/sanction-checks",
        name: "sanction-checks",
        component: () => import("@/views/sanction-check/List.vue"),
      },
      {
        path: "/list-process/:id",
        name: "listProcess",
        redirect: to => ({
          name: "listProcessOverview",
          params: {id: to.params.id},
        }),
        component: () => import("@/views/process/ProcessPage.vue"),
        props: route => ({type: route.params.id}),
        children: [
          {
            path: "/list-process/:id/overview",
            name: "listProcessOverview",
            component: () => import("@/views/process/ProcessOverview.vue"),
            props: route => ({type: route.params.id})
          },
          {
            path: "/list-process/:id/list",
            name: "listProcessList",
            component: () => import("@/views/process/ProcessSearch.vue"),
            props: route => ({type: route.params.id})
          },
        ]
      },
      {
        path: "/team-tasks",
        name: "teamTasks",
        component: () => import("@/views/task/MyTeamTasks.vue"),
      },
      {
        path: "/task-search",
        name: "taskSearch",
        component: () => import("@/views/task/Search.vue"),
      },
      {
        path: "/claims-dashboard",
        name: "claimsDashboard",
        component: () => import("@/views/claim/Dashboard.vue"),
      },
      {
        path: "/claim-reminders-due",
        name: "claim-reminders-due",
        component: () => import("@/views/claim/RemindersDue.vue"),
      },
      {
        path: "/claim-approval-requests",
        name: "claim-approval-requests",
        component: () => import("@/views/claim/ApprovalRequests.vue"),
      },
      {
        path: "/claim-review-due",
        name: "claim-review-due",
        component: () => import("@/views/claim/ReviewDue.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot",
        component: () =>
          import("@/views/auth/Forgot.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/file-share/:fileId",
    name: "viewShare",
    component: () => import("@/views/fileshare/ViewFileShare.vue"),
    props: route => ({
      inputs: {fileId: route.params.fileId}
    })
  },
  {
    path: "/form",
    name: "publicForm",
    component: () => import("@/views/custome-form/PublicForm.vue"),
    props: route => ({
      source: route.query.source
    })
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/Error403.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  // reset config to initial state
  const resetPage = ['/profile/change-password'];
  const resetRequired = !resetPage.includes(to.path);

  if (resetRequired) {
    if (JwtService.getResetPassword()) {
      return next({
        name: 'changePassword',
      });
    }
  }
  const publicPages = ['/sign-in', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const logged = JwtService.getToken()
  const isUpdateValue = to.path.includes("/file-share") || to.path.includes("/form");
  if (isUpdateValue) {
    return next()
  } else {
    store.dispatch(Actions.VERIFY_AUTH);
    if (!logged && authRequired) {
      return next({
        path: '/sign-in',
        query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
      });
    }
    next()
  }

});
export default router;