import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {ITableResponse} from "@/core/entity/ITableResponse";
import type {ICaseView} from "@/core/entity/ICaseView";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import type {ISearchFilter} from "@/core/entity/ISearchFilter";
import CaseService from "@/core/services/CaseService";
import type {ICase} from "@/core/entity/ICase";
import CookieService from "@/core/services/CookieService";
import {COOKIE_KEY} from "@/core/config/Constant";
import ClaimService from "@/core/services/ClaimService";


@Module
export default class CaseModule extends VuexModule {
  caseViewPage: ITableResponse<ICaseView> = {
    total: 0,
    data: [],
    pages: 0,
  }
  case: any;
  editingCase: any

  watchingCases: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }
  claimPage: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }


  @Action
  [Actions.LOAD_CASE_CLAIMS](filter: any) {
    ClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CASE_CLAIMS, res);
    })
  }

  @Action
  [Actions.LOAD_WATCHING_CASES](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    CaseService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_WATCHING_CASES, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false);
    })
  }

  @Action
  [Actions.CASE_SEARCH](filter: ISearchFilter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    CaseService.search(filter).then(res => {
      this.context.commit(Mutations.SET_CASE_SEARCH, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false);
    })
  }

  @Action
  async [Actions.LOAD_CASE](id: string) {
    this.context.dispatch(Actions.SET_RECENT_CASE, id);
    await CaseService.get<any>(id).then(res => {
      this.context.commit(Mutations.EMIT_ACTIVITY_ACCESS, {
        createActivities: res?.access?.createActivities,
        viewPendingActivities: res?.access?.viewPendingActivities
      })
      this.context.commit(Mutations.EMIT_RESERVE_ACCESS, {
        updateReserve: res?.access?.updateReserve,
        editCurrentReserve: res?.access?.editCurrentReserve
      })
      this.context.commit(Mutations.EMIT_DOCUMENT_ACCESS, {uploadDocument: res?.access?.uploadDocument})
      this.context.commit(Mutations.SET_CASE, res)
      this.context.commit(Mutations.SET_RESERVE, res?.reserve)
      this.context.commit(Mutations.SET_CLIENT_TEMPLATE_SELECT, res?.client?.id)
    }).finally(() => {
      this.context.commit(Mutations.SET_ENTITY_LOADING, false)
    })
  }


  @Action
  [Actions.SET_RECENT_CASE](id) {
    const recentCases = CookieService.get(COOKIE_KEY.RECENT_CASES)
    if (!recentCases) {
      CookieService.save(COOKIE_KEY.RECENT_CASES, Array.of(id))
    } else {
      const list = JSON.parse(recentCases);
      if (!list.includes(id)) {
        list.push(id)
      }
      CookieService.save(COOKIE_KEY.RECENT_CASES, list)
    }
  }

  @Action [Actions.EDIT_CASE](data: any) {
    this.context.commit(Mutations.SET_EDIT_CASE, data)
  }

  @Mutation
  [Mutations.SET_CASE_SEARCH](data: ITableResponse<ICaseView>) {
    this.caseViewPage = data
  }

  @Mutation
  [Mutations.SET_CASE](data: ICase) {
    this.case = data
  }

  @Mutation
  [Mutations.SET_WATCHING_CASES](data) {
    this.watchingCases = data
  }

  @Mutation
  [Mutations.SET_EDIT_CASE](data: ICase) {
    this.editingCase = data
  }

  @Mutation
  [Mutations.SET_CASE_CLAIMS](data) {
    this.claimPage = data
  }
}