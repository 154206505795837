import {BaseService} from "@/core/services/BaseService";

class ServicePaymentService extends BaseService<any> {
  endpoint = "/service-payment"

  async overview(id) {
    return this.http().get(this.endpoint + "/caze/" + id).then(res => {
      return res.data;
    })
  }
}

export default new ServicePaymentService()