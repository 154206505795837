import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import UserService from "@/core/services/UserService";
import ChatService from "@/core/services/ChatService";

@Module
export default class ChatModule extends VuexModule {

  contacts: any = [];

  toId: string = '';
  currentToUser: any = {};
  unread = 0;

  boxMessage: any = {
    toId: '',
    messages: <any[]>[],
    start: -1,
  }

  @Action
  [Actions.LOAD_CHAT_CONTACTS]() {
    UserService.contacts().then(res => {
      this.context.commit(Mutations.SET_CHAT_CONTACTS, res)
    })
  }


  @Action
  [Actions.LOAD_BOX_MESSAGE](filter) {
    ChatService.load(filter.toId, filter.start, filter.max).then(res => {
      this.context.commit(Mutations.SET_BOX_MESSAGE, {messages: res, toId: filter.to, start: filter.start})
    })
  }


  @Action
  [Actions.RECEIVE_CHAT_MESSAGE](message) {
    console.log("set chat message")
    this.context.commit(Mutations.SET_CHAT_MESSAGE, message);
  }

  @Action
  [Actions.LOAD_SET_TO_ID](contact) {
    if (this.currentToUser.id) {
      this.context.commit(Mutations.WS_UN_SUB_TOPIC, `/user/topic/chat/${this.currentToUser.id}/message`)
    }
    this.context.commit(Mutations.SET_TO_ID, contact);
  }

  @Action
  [Actions.SEND_CHAT_MESSAGE](data) {
    this.context.commit(Mutations.WS_SEND, {destination: '/app/auth/chat', data: JSON.stringify(data)})
  }

  @Mutation
  [Mutations.SET_CHAT_CONTACTS](data) {
    data.sort(function (a, b) {
      return b.unread - a.unread;
    });
    this.contacts = data;
    this.unread = this.contacts.reduce((acc, message) => acc + message.unread, 0);

  }

  @Mutation
  [Mutations.SET_BOX_MESSAGE](data) {
    this.boxMessage.messages = data.messages;
  }


  @Mutation
  [Mutations.SET_CHAT_MESSAGE](mess) {
    const messages = this.boxMessage.messages;
    messages.push(mess);
    this.boxMessage.messages = messages;
  }

  @Mutation
  [Mutations.SET_TO_ID](contact) {
    this.currentToUser = contact;
  }

  @Mutation
  [Mutations.SET_CONTACT_STATUS](payload) {
    console.log(payload)
    this.contacts.map(item => {
      if (item.name == payload.email) {
        item.online = payload.online
      }
    })
  }

  @Mutation
  [Mutations.SET_CONTACT_READ](payload) {
    this.contacts.map(item => {
      if (item.id == payload.id) {
        item.unread = payload.unread
      }
    })
    this.contacts.sort(function (a, b) {
      return b.unread - a.unread;
    });
    this.unread = this.contacts.reduce((acc, message) => acc + message.unread, 0);
  }
}
