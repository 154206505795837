import { BaseService } from "@/core/services/BaseService";
import { ENDPOINT } from "@/core/config/Constant";
import { ICompany } from "@/core/entity/ICompany";

class CompanyService extends BaseService<any> {
  endpoint = ENDPOINT.COMPANY

  async list(): Promise<ICompany[]> {
    return await this.http().get<ICompany[]>(this.endpoint + "?f=id,name").then(res => {
      return res.data
    })
  }

  async clients(): Promise<ICompany[]> {
    return await this.http().get<ICompany[]>(this.endpoint + "?type=CLIENT&f=id,name,config,code").then(res => {
      return res.data
    })
  }

  async all(): Promise<any> {
    return await this.http().get<ICompany[]>(this.endpoint + "?types=CLIENT,TRIDENT&f=id,name,config").then(res => {
      return res.data
    })
  }

  async getFee(id): Promise<any> {
    return await this.http().get<any>(this.endpoint + "/" + id + "/fee").then(res => {
      return res.data
    })
  }
}

export default new CompanyService()