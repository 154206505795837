import {BaseService} from "@/core/services/BaseService";
import {buildFormData, generatorQueryString} from "@/core/helpers/functions";

class ClaimService extends BaseService<any> {
  endpoint = '/claim'

  async updateStatus(id, body) {
    return await this.http().put(this.endpoint + "/" + id + "/status", body).then(res => {
      return res.data
    })
  }

  async moveClaim(id: any, value) {
    return this.http().put(this.endpoint + "/" + id + "/move", value).then(res => {
      return res.data
    })
  }

  async emailFormat(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/email/format", data).then(res => {
      return res.data
    })
  }

  async sendAck(id, data, files) {
    const formData = buildFormData(data);
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return this.uploadHttp().post(this.endpoint + "/" + id + "/email/ack", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data
    })
  }

  async updateDoc(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/doc-status", data).then(res => {
      return res.data;
    })
  }

  async updateClaimDoc(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/doc-claim-status", data).then(res => {
      return res.data;
    })
  }

  async updateDecision(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/decision", data).then(res => {
      return res.data;
    })
  }

  async updateApproval(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/approval", data).then(res => {
      return res.data;
    })
  }

  async reserveOutstanding(id) {
    return await this.http().get(this.endpoint + "/" + id + "/outstanding-reserve").then(res => {
      return res.data;
    })
  }

  async fastTrack(filter) {
    const queryString = generatorQueryString(filter);
    return this.http().get<any>(this.endpoint + "/fast-track?" + queryString).then(res => {
      return res.data
    })
  }

  async scan(id) {
    return this.http().get(this.endpoint + "/" + id + "/scan").then(res => {
      return res.data;
    })
  }

  async secureLink(id) {
    return this.http().get(this.endpoint + "/" + id + "/secure-link").then(res => {
      return res.data
    })
  }

  async updateGhipDetail(id, data) {
    return this.http().put(this.endpoint + "/" + id + "/ghip-detail", data).then(res => {
      return res.data
    })
  }

  async updateGhipStatus(claimId: string, model: any) {
    return this.http().put(this.endpoint + "/" + claimId + "/ghip-status", model).then(res => {
      return res.data
    })
  }

  async checkGhipComplete(id) {
    return this.http().get(this.endpoint  +"/" + id + "/check-ghip-complete").then(res => {
      return res.data
    })
  }
}

export default new ClaimService();