import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ExchangeRateService from "@/core/services/ExchangeRateService";
import CurrencyService from "@/core/services/CurrencyService";


@Module
export default class ExchangeRateModule extends VuexModule {
  rates: any
  currencies: any;

  @Action
  [Actions.LOAD_EXCHANGE_RATES]() {
    ExchangeRateService.list().then(res => {
      this.context.commit(Mutations.SET_EXCHANGE_RATES, res)
    })
  }

  @Action
  [Actions.LOAD_CURRENCIES]() {
    CurrencyService.list().then(res => {
      this.context.commit(Mutations.SET_CURRENCIES, res)
    })
  }

  @Mutation
  [Mutations.SET_EXCHANGE_RATES](data) {
    this.rates = data
  }

  @Mutation
  [Mutations.SET_CURRENCIES](data) {
    this.currencies = data
  }
}