import {BaseService} from "@/core/services/BaseService";

class CustomerInboxService extends BaseService<any> {
  endpoint = "/customer-inbox"

  async load(cusId, page, max) {
    return this.http().get(this.endpoint + "/load/" + cusId + "/" + page + "/" + max).then(res => {
      return res.data;
    })
  }

  async read(customerId) {
    return this.http().get(this.endpoint + "/read/" + customerId).then(res => {
      return res.data
    })
  }
}

export default new CustomerInboxService();