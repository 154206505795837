import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import CustomerService from "@/core/services/CustomerService";


@Module
export default class CustomerModule extends VuexModule {
  page: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }
  entity = <any>{};

  @Action
  [Actions.LOAD_CUSTOMERS](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    CustomerService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_CUSTOMERS, {loading: false, data: res})
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  [Actions.LOAD_CUSTOMER](id) {
    CustomerService.get(id).then(res => {
      this.context.commit(Mutations.SET_CUSTOMER, res)
    })
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](data) {
    this.page = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER](data) {
    this.entity = data;
  }


}