import LookupService from "@/core/services/LookupService";

export default {
  mounted(el, binding, vnode) {

    el.addEventListener('blur', (e) => {
      const code = e.target.value;
      if (code) {
        const type = el.dataset.type
        LookupService.checkCode(type, code).then(res => {

          if (res.status === false) {
            el.classList.add("is-invalid")
          } else {
            el.classList.remove("is-invalid")
            if (res.type === 'REV') {
              const id = vnode.props['data-rev-id'];
              binding.instance.model.lines[id].desc = res.desc;
            }
            if (res.type === 'HCPCS') {
              const id = vnode.props['data-cpt-id'];
              binding.instance.model.lines[id].desc = res.desc;
            }
          }
        })
      } else {
        el.classList.remove("is-invalid")
      }
    }, true);
  }
}