import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";
import {ILocation} from "@/core/entity/ILocation";

class LocationService extends BaseService<any> {
  endpoint = ENDPOINT.LOCATION;

  async list(type: string): Promise<ILocation[]> {
    return await this.http().get<ILocation[]>(this.endpoint + "?type=" + type).then(res => {
      return res.data
    })
  }

  async listByCode(code: string): Promise<ILocation[]> {
    return await this.http().get<ILocation[]>(this.endpoint + "/" + code).then(res => {
      return res.data
    })
  }

  async getVerify(line1, city) {
    return await this.http().get(this.endpoint + "/address/verify?line1=" + line1 + "&city=" + city).then(res => {
      return res.data
    })
  }

  async autoComplete(line1) {
    return await this.http().get(this.endpoint + "/address/completion?line1=" + line1).then(res => {
      return res.data
    })
  }
}

export default new LocationService()