import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import TagService from "@/core/services/TagService";

@Module
export default class TagModule extends VuexModule {
  page: any = {
    loading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }


  @Action
  async [Actions.LOAD_TAGS](filter: any) {
    await TagService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TAGS, {loading: false, data: res})
    })
  }

  @Mutation
  [Mutations.SET_TAGS](payload) {
    this.page.loading = payload.loading;
    this.page.data = payload.data;
  }

  @Mutation
  [Mutations.ADDED_TAGS]() {

  }
}