import {BaseService} from "@/core/services/BaseService";

class ReminderService extends BaseService<any> {
  endpoint = "/reminder"

  async complete(id, data) {
    return this.http().put(this.endpoint + "/" + id + "/complete", data).then(res => {
      return res.data
    })
  }
}

export default new ReminderService();